import React, { FunctionComponent, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { DialogAbstract } from '../contents/DialogAbstract';
import { ContentDto } from '../../dto/ContentDto';
import {
  ABSTRACT_TYPE,
  CASE_TYPE,
  CRIB_TYPE,
  POOL_TYPE,
  TEST_IMAGE_TYPE,
  TEST_TYPE,
  TRID_ABSTRACT_EXTENDED_TYPE,
  TRID_ABSTRACT_TYPE,
  TRID_ATLAS_PLUS_TYPE,
  TRID_ATLAS_TYPE,
  TRID_VOICE_TYPE,
  VIDEO_TYPE,
} from '../../constant/constants';
import { DialogPool } from '../contents/DialogPool';
import { DialogTest } from '../contents/DialogTest';
import { DialogTestImg } from '../contents/DialogTestImg';
import { DialogCase } from '../contents/DialogCase';
import { DialogCrib } from '../contents/DialogCrib';
import { DialogVideo } from '../contents/DialogVideo';
import { DialogAbstract3D } from '../contents/DialogAbstract3D';
import { DialogAbstract3DPlus } from '../contents/DialogAbstract3DPlus';
import { DialogAtlas3D } from '../contents/DialogAtlas3D';
import { DialogAtlas3DPlus } from '../contents/DialogAtlas3DPlus';
import { DialogAudio3D } from '../contents/DialogAudio3D';

interface Props {
  imageContent: any;
  type: number;
  onCloseEvent: () => void;
  tooltip: string;
  isEdit?: boolean;
  dto?: ContentDto;
  isFind?: boolean;
}

export const ContentItemDiv: FunctionComponent<Props> = ({
  imageContent,
  dto,
  type,
  onCloseEvent,
  tooltip,
  isEdit,
  isFind,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    onCloseEvent();
  };
  return (
    <div>
      <Stack alignItems="center" justifyContent="center">
        <IconButton
          size="medium"
          title={tooltip}
          disabled={!isFind && !isEdit}
          onClick={handleOpen}
        >
          {imageContent}
        </IconButton>
      </Stack>
      {type === ABSTRACT_TYPE && (
        <DialogAbstract
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === POOL_TYPE && (
        <DialogPool
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === TEST_TYPE && (
        <DialogTest
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === TEST_IMAGE_TYPE && (
        <DialogTestImg
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === CASE_TYPE && (
        <DialogCase
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === CRIB_TYPE && (
        <DialogCrib
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === VIDEO_TYPE && (
        <DialogVideo
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === TRID_ABSTRACT_TYPE && (
        <DialogAbstract3D
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === TRID_ABSTRACT_EXTENDED_TYPE && (
        <DialogAbstract3DPlus
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === TRID_ATLAS_TYPE && (
        <DialogAtlas3D
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === TRID_ATLAS_PLUS_TYPE && (
        <DialogAtlas3DPlus
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
      {type === TRID_VOICE_TYPE && !isEdit && (
        <DialogAudio3D
          open={openDialog}
          dto={dto}
          isEdit={isEdit}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

ContentItemDiv.defaultProps = {
  isEdit: false,
  dto: undefined,
  isFind: false,
};
