import React from 'react';
import {
  AppBar,
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTE, SIGN_IN_ROUTE } from '../../constant/routes';
import Logo from '../../image/logo.jpg';
import { MAIN_COLOR_STRING } from '../../constant/constants';

export const MainNavigationBar = () => {
  const navigate = useNavigate();

  const darkTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: MAIN_COLOR_STRING,
      },
    },
  });

  const logout = () => {
    navigate(SIGN_IN_ROUTE);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack
                direction="row"
                alignItems="center"
                onClick={() => navigate(MAIN_ROUTE)}
                spacing={2}
              >
                <img
                  alt=""
                  src={Logo}
                  width={80}
                  style={{ cursor: 'pointer' }}
                />
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '24px',
                    color: '#FFFFFF',
                  }}
                  component="text"
                >
                  mistrecov
                </Typography>
              </Stack>
            </Stack>
            <div style={{ flex: 1 }}> </div>
            <Stack direction="row" alignItems="center" spacing={2}>
              <IconButton
                size="large"
                onClick={logout}
                sx={{ color: '#ffFFff' }}
              >
                <LogoutIcon />
              </IconButton>
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
};
