import { JSON_CONTENT_TYPE } from '../../constant/constants';
import { IApiParams } from '../../interface/IApiParams';
import { secureToken } from '../../utils/utils';
import { getApiTelegramBot } from '../getApiTelegramBot';

export const apiGetTelegramBotUsers = async () => {
  const params: IApiParams = {
    url: `/getUsers.php?${secureToken()}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': 'telega',
    },
  };
  return getApiTelegramBot(params, 'GET');
};
