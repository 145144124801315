import { JSON_CONTENT_TYPE } from '../../constant/constants';
import { UpdateRequestDto } from '../../dto/UpdateRequestDto';
import { IApiParams } from '../../interface/IApiParams';
import { secureToken } from '../../utils/utils';
import { getApiTelegramBot } from '../getApiTelegramBot';

export const apiCloseRequest = async (dto: UpdateRequestDto) => {
  const params: IApiParams = {
    url: `/endRequest.php?${secureToken()}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': 'telega',
    },
  };
  return getApiTelegramBot(params, 'POST', dto);
};
