import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  DESIGN_ROUTE,
  EDIT_ROUTE,
  MAIN_ROUTE,
  MESSAGES_ROUTE,
  RELEASE_ROUTE,
  SEARCH_ROUTE,
  SHOPS_ROUTE,
  SIGN_IN_ROUTE,
  STATS_ROUTE,
  TASK_ROUTE,
  TRID_ROUTE,
} from '../../constant/routes';
import { MainPage } from '../../page/MainPage';
import { SignInPage } from '../../page/SignInPage';
import { SectionPage } from '../../page/SectionPage';
import { SearchPage } from '../../page/SearchPage';
import { PurchasePage } from '../../page/PurchasePage';
import { EditPage } from '../../page/EditPage';
import { TridPage } from '../../page/TridPage';
import { DesignPage } from '../../page/DesignPage';
import { ReleasePage } from '../../page/ReleasePage';
import { MessagesPage } from '../../page/MessagesPage';
import { StatsPage } from '../../page/StatsPage';
import { CorrectTaskPage } from '../../page/CorrectTaskPage';

export const App = () => (
  <div>
    <Routes>
      <Route path={SIGN_IN_ROUTE} element={<SignInPage />} />
      <Route path={MAIN_ROUTE} element={<MainPage />} />
      <Route path={`${MAIN_ROUTE}/:section`} element={<SectionPage />} />
      <Route path={`${SEARCH_ROUTE}/:section`} element={<SearchPage />} />
      <Route path={`${SHOPS_ROUTE}/:section`} element={<PurchasePage />} />
      <Route path={`${DESIGN_ROUTE}/:section`} element={<DesignPage />} />
      <Route path={`${EDIT_ROUTE}/:section/:key`} element={<EditPage />} />
      <Route path={`${TRID_ROUTE}/:section`} element={<TridPage />} />
      <Route path={`${TASK_ROUTE}/:section`} element={<CorrectTaskPage />} />
      <Route path={`${RELEASE_ROUTE}/:section`} element={<ReleasePage />} />
      <Route path={`${MESSAGES_ROUTE}/:section`} element={<MessagesPage />} />
      <Route path={`${STATS_ROUTE}/:section`} element={<StatsPage />} />
    </Routes>
  </div>
);

export default App;
