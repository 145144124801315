import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyTextFiled } from '../UI/MyTextField';
import { NewsDto } from '../../dto/NewsDto';
import { AddNewsDto } from '../../dto/AddNewsDto';
import { apiGetNews } from '../../service/apiGetNews';
import { apiAddNews } from '../../service/apiAddNews';
import { apiDeleteNews } from '../../service/apiDeleteNews';

export const NewsDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [newsDtos, setNewsDtos] = useState<NewsDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);

  const [addNewsDto, setAddNewsDto] = useState<AddNewsDto>({
    title: '',
    message: '',
  });

  const getNews = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetNews();
    if (apiResult.isSuccess) {
      setNewsDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const addNews = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiAddNews(addNewsDto);
    if (apiResult.isSuccess) {
      setAddNewsDto({
        ...addNewsDto,
        title: '',
        message: '',
      });
      getNews();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const deleteNews = async (dto: NewsDto) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiDeleteNews(dto);
    if (apiResult.isSuccess) {
      getNews();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    getNews();
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'datetime',
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Дата и время
        </Typography>
      ),
      width: 200,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.datetime}
        </Typography>
      ),
    },
    {
      field: 'title',
      width: 200,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Заголовок
        </Typography>
      ),
      flex: 1,
      minWidth: 350,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.title}
        </Typography>
      ),
    },
    {
      field: 'message',
      width: 200,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Новость
        </Typography>
      ),
      flex: 1,
      minWidth: 350,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.message}
        </Typography>
      ),
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Удалить"
            size="small"
            onClick={() => deleteNews(row.row)}
          >
            <DeleteIcon fontSize="small" sx={{ color: 'red' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" spacing={1}>
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        <Stack>
          <Stack direction="row" spacing={1}>
            <Stack width="100%" spacing={1}>
              <MyTextFiled
                value={addNewsDto?.title}
                size="small"
                onChange={(e) =>
                  setAddNewsDto({
                    ...addNewsDto,
                    title: e.target.value,
                  })
                }
                multiLine
                label="Заголовок"
              />
              <MyTextFiled
                value={addNewsDto?.message}
                size="small"
                onChange={(e) =>
                  setAddNewsDto({
                    ...addNewsDto,
                    message: e.target.value,
                  })
                }
                multiLine
                label="Сообщение"
              />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                size="large"
                disabled={addNewsDto.title === '' || addNewsDto.message === ''}
                onClick={() => addNews()}
              >
                <SendIcon fontSize="large" sx={{ color: 'blue' }} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                  py: 1,
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: '5px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                  py: '10px',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                  {
                    outline: 'none !important',
                  },
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              rows={newsDtos}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...other}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </ThemeProvider>
        </Box>
      </Stack>
    </Stack>
  );
};
