import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Stack, Typography } from '@mui/material';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM, SECTION_NAME } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiGetEdit } from '../../service/apiGetEdit';
import { ContentDto } from '../../dto/ContentDto';
import { RowSectionDivItem } from '../section/RowSectionDivItem';
import { DialogEditDiv } from './DialogEditDiv';

export const EditDiv = () => {
  const { section, key } = useParams();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [editDtos, setEditDtos] = useState<ContentDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDto, setSelectedDto] = useState<ContentDto>();
  const handleOpen = (dto: ContentDto) => {
    setSelectedDto(dto);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const getEdit = async (route: string, title: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetEdit(route, title);
    if (apiResult.isSuccess) {
      setEditDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    if (!openDialog) {
      getEdit(section ?? '', key ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="90%" padding="10px" spacing={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 1000,
              fontSize: '30px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`${localStorage.getItem(SECTION_NAME)} - Редактирование раздела`}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <div> </div>
          </Stack>
        </Stack>
        <Divider />
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        {editDtos.map((item) => (
          <div>
            <RowSectionDivItem
              key={item.id}
              dto={item}
              isEdit
              onClick={() => handleOpen(item)}
              onCloseEvent={() => getEdit(section ?? '', key ?? '')}
            />
            <Divider />
          </div>
        ))}
      </Stack>
      <DialogEditDiv
        open={openDialog}
        dto={selectedDto}
        onClose={handleClose}
      />
    </Stack>
  );
};
