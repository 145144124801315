import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Stack, Typography } from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { MyTextFiled } from '../UI/MyTextField';
import { CribDto } from '../../dto/CribDto';
import { apiSaveCrib } from '../../service/apiSaveCrib';
import { MyAlert } from '../UI/MyAlert';

interface Props {
  num: number;
  isEdit: boolean;
  keyTitle: string;
  titleName: string;
  dto?: CribDto;
}

export const DialogCribItem: FunctionComponent<Props> = ({
  num,
  isEdit,
  keyTitle,
  titleName,
  dto,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const { section } = useParams();

  const [cribDto, setCribDto] = useState(dto);

  const saveText = async (route: string, key: string, title: string) => {
    const apiResult: IApiResult = await apiSaveCrib(route, key, title, cribDto);
    if (apiResult.isSuccess) {
      setAlertProps({
        message: 'Данные записаны',
        severity: AlertSeverityEnum.info,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <Stack direction="row" width="100%" alignItems="center" spacing={1}>
          {isEdit && (
            <Stack direction="row" spacing={1}>
              <IconButton
                title="Сохранить"
                size="small"
                onClick={() => saveText(section ?? '', keyTitle, titleName)}
              >
                <SaveIcon fontSize="large" sx={{ color: '#2196f3' }} />
              </IconButton>
            </Stack>
          )}
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              width: '100%',
              fontSize: '18px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`Подсказка ${num}`}
          </Typography>
        </Stack>
        <Stack spacing={1} width="100%">
          {!isEdit && (
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                width: '100%',
                fontSize: '18px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`${cribDto?.crib}`}
            </Typography>
          )}
          {isEdit && (
            <MyTextFiled
              value={cribDto?.crib}
              multiLine
              onChange={(e) =>
                cribDto !== undefined &&
                setCribDto({ ...cribDto, crib: e.target.value })
              }
              size="small"
              label="Подсказка"
            />
          )}
        </Stack>
      </Stack>
    </div>
  );
};

DialogCribItem.defaultProps = {
  dto: undefined,
};
