import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CloseIcon from '@mui/icons-material/Close';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { CheckContentDto } from '../../dto/CheckContentDto';
import { apiCheckGetContents } from '../../service/apiCheckGetContents';
import { CheckCaseDto } from '../../dto/CheckCaseDto';
import { CheckCribDto } from '../../dto/CheckCribDto';
import { CheckTestDto } from '../../dto/CheckTestDto';
import { CheckTestImgDto } from '../../dto/CheckTestImgDto';
import { apiCheckGetCases } from '../../service/apiCheckGetCases';
import { apiCheckGetCribs } from '../../service/apiCheckGetCribs';
import { apiCheckGetTests } from '../../service/apiCheckGetTests';
import { apiCheckGetTestImages } from '../../service/apiCheckGetTestImages';
import { apiBlocBase } from '../../service/apiBlocBase';
import { BaseDto } from '../../dto/BaseDto';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogCheck: FunctionComponent<Props> = ({ open, onClose }) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { section } = useParams();
  const [checkContentsDto, setCheckContentsDto] = useState<CheckContentDto>();
  const [checkCasesDto, setCheckCasesDto] = useState<CheckCaseDto>();
  const [checkCribsDto, setCheckCribsDto] = useState<CheckCribDto>();
  const [checkTestsDto, setCheckTestsDto] = useState<CheckTestDto>();
  const [checkTestImagesDto, setCheckTestImagesDto] =
    useState<CheckTestImgDto>();

  const checkContents = async (route: string) => {
    const apiResult: IApiResult = await apiCheckGetContents(route);
    if (apiResult.isSuccess) {
      setCheckContentsDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const checkCases = async (route: string) => {
    const apiResult: IApiResult = await apiCheckGetCases(route);
    if (apiResult.isSuccess) {
      setCheckCasesDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const checkCribs = async (route: string) => {
    const apiResult: IApiResult = await apiCheckGetCribs(route);
    if (apiResult.isSuccess) {
      setCheckCribsDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const checkTests = async (route: string) => {
    const apiResult: IApiResult = await apiCheckGetTests(route);
    if (apiResult.isSuccess) {
      setCheckTestsDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const checkTestImg = async (route: string) => {
    const apiResult: IApiResult = await apiCheckGetTestImages(route);
    if (apiResult.isSuccess) {
      setCheckTestImagesDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleClose = () => {
    onClose();
    setCheckContentsDto(undefined);
    setCheckCasesDto(undefined);
    setCheckCribsDto(undefined);
    setCheckTestsDto(undefined);
    setCheckTestImagesDto(undefined);
  };

  const blocBase = async (route: string) => {
    const dto: BaseDto = {
      bloc: 1,
    };
    const apiResult: IApiResult = await apiBlocBase(route, dto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleBloc = () => {
    blocBase(section ?? '');
  };

  useEffect(() => {
    if (open) {
      checkContents(section ?? '');
      checkCases(section ?? '');
      checkCribs(section ?? '');
      checkTests(section ?? '');
      checkTestImg(section ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%' },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '24px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              Результаты проверок
            </Typography>
            <Stack direction="row" spacing={1}>
              {checkContentsDto?.error === 0 &&
                checkCasesDto?.error === 0 &&
                checkCribsDto?.error === 0 &&
                checkTestsDto?.error === 0 &&
                checkTestImagesDto?.error === 0 && (
                  <IconButton size="small" onClick={handleBloc}>
                    <LockOpenIcon fontSize="large" sx={{ color: 'green' }} />
                  </IconButton>
                )}
              <IconButton title="Закрыть" size="small" onClick={handleClose}>
                <CloseIcon fontSize="large" sx={{ color: '#000000' }} />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '18px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              JSON
            </Typography>
            <Divider />
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color:
                  checkContentsDto?.json === 'valid json' ? 'green' : 'red',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`CONTENTS - ${checkContentsDto?.json}`}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color: checkCasesDto?.json === 'valid json' ? 'green' : 'red',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`CASES - ${checkCasesDto?.json}`}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color: checkCribsDto?.json === 'valid json' ? 'green' : 'red',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`CRIBS - ${checkCribsDto?.json}`}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color: checkTestsDto?.json === 'valid json' ? 'green' : 'red',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`TESTS - ${checkTestsDto?.json}`}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color:
                  checkTestImagesDto?.json === 'valid json' ? 'green' : 'red',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`TEST IMAGES - ${checkTestImagesDto?.json}`}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                marginTop: '20px',
                fontWeight: 500,
                fontSize: '18px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`Лекций не найдено - ${checkContentsDto?.abstractNotFoundCnt}`}
            </Typography>
            <Divider />
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {checkContentsDto?.abstractNotFound.replaceAll(';/;', '\n')}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                marginTop: '20px',
                fontWeight: 500,
                fontSize: '18px',
                color: '#000000',
                letterSpacing: 1,
                whiteSpace: 'pre-line',
              }}
              component="text"
            >
              {`Латинских карточек не найдено - ${checkContentsDto?.poolNotFoundCnt}`}
            </Typography>
            <Divider />
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color: '#000000',
                letterSpacing: 1,
                whiteSpace: 'pre-line',
              }}
              component="text"
            >
              {checkContentsDto?.poolNotFound.replaceAll(';/;', '\n')}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                marginTop: '20px',
                fontWeight: 500,
                fontSize: '18px',
                color: '#000000',
                letterSpacing: 1,
                whiteSpace: 'pre-line',
              }}
              component="text"
            >
              {`Латинские карточки с ошибкой - ${checkContentsDto?.poolFormatCnt}`}
            </Typography>
            <Divider />
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {checkContentsDto?.poolFormat.replaceAll(';/;', '\n')}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                marginTop: '20px',
                fontWeight: 500,
                fontSize: '18px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`Изображений из лекций не найдено - ${checkContentsDto?.imageFromAbstractNotFoundCnt}`}
            </Typography>
            <Divider />
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {checkContentsDto?.imageFromAbstractNotFound.replaceAll(
                ';/;',
                '\n'
              )}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                marginTop: '20px',
                fontWeight: 500,
                fontSize: '18px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`Изображений из тестов не найдено - ${checkTestImagesDto?.imageFromTestNotFoundCnt}`}
            </Typography>
            <Divider />
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {checkTestImagesDto?.imageFromTestNotFound.replaceAll(
                ';/;',
                '\n'
              )}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                marginTop: '20px',
                fontWeight: 500,
                fontSize: '18px',
                color: '#000000',
                letterSpacing: 1,
                whiteSpace: 'pre-line',
              }}
              component="text"
            >
              {`Возможно проблемные ссылки в лекциях - ${checkContentsDto?.problemLinkInAbstractCnt}`}
            </Typography>
            <Divider />
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                color: '#000000',
                letterSpacing: 1,
                whiteSpace: 'pre-line',
              }}
              component="text"
            >
              {checkContentsDto?.problemLinkInAbstract.replaceAll(';/;', '\n')}
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
