import React, { FunctionComponent, useEffect, useState } from 'react';
import FileDownload from 'js-file-download';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { IApiResult } from '../../interface/IApiResult';
import {
  API_STATUS_PROBLEM,
  DOWNLOAD_FILE_NUM,
  TEST_TYPE,
} from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyAlert } from '../UI/MyAlert';
import { ContentDto } from '../../dto/ContentDto';
import { TestDto } from '../../dto/TestDto';
import { apiGetTest } from '../../service/apiGetTest';
import { DialogTestItem } from './DialogTestItem';
import { apiDeleteContent } from '../../service/apiDeleteContent';
import { AddFile } from '../add-file/AddFile';
import { BackDropDiv } from '../backdrop/BackDropDiv';

interface Props {
  open: boolean;
  isEdit?: boolean;
  onClose: () => void;
  dto?: ContentDto;
}

export const DialogTest: FunctionComponent<Props> = ({
  open,
  dto,
  isEdit,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [testDtos, setTestDtos] = useState<TestDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);
  let numTest = 0;

  const handleClose = () => {
    setTestDtos([]);
    onClose();
  };
  const { section } = useParams();

  const getTest = async (route: string, key: string, download?: number) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetTest(route, key, download);
    if (apiResult.isSuccess) {
      if (download === undefined) {
        if (apiResult.data.test !== undefined) {
          setTestDtos(apiResult.data.test);
        }
      } else {
        FileDownload(
          apiResult.data,
          `${route}_2D_тест_обычный_${dto?.key}_${dto?.title}.txt` ?? ''
        );
      }
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const deleteContent = async (route: string, type: number, key: string) => {
    const apiResult: IApiResult = await apiDeleteContent(route, type, key);
    if (apiResult.isSuccess) {
      setTestDtos([]);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      getTest(section ?? '', dto?.key ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%', height: '100%' },
        }}
      >
        <BackDropDiv open={backDrop} />
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '24px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`2D тесты - ${dto?.key} ${dto?.title}`}
            </Typography>
            <Stack direction="row" spacing={1}>
              {!isEdit && (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    title="Скачать"
                    size="small"
                    onClick={() =>
                      getTest(section ?? '', dto?.key ?? '', DOWNLOAD_FILE_NUM)
                    }
                  >
                    <DownloadIcon fontSize="large" sx={{ color: '#9c27b0' }} />
                  </IconButton>
                </Stack>
              )}
              {isEdit && (
                <Stack direction="row" spacing={1}>
                  <AddFile
                    name="fileToUpload"
                    icon={
                      <FileUploadIcon
                        fontSize="large"
                        sx={{ color: '#9c27b0' }}
                      />
                    }
                    typeContent={TEST_TYPE}
                    dto={dto}
                  />
                  <IconButton
                    title="Удалить"
                    size="small"
                    onClick={() =>
                      deleteContent(
                        section ?? '',
                        TEST_TYPE,
                        dto?.key ?? '-11111'
                      )
                    }
                  >
                    <DeleteIcon fontSize="large" sx={{ color: 'red' }} />
                  </IconButton>
                </Stack>
              )}
              <IconButton title="Закрыть" size="small" onClick={handleClose}>
                <CloseIcon fontSize="large" sx={{ color: '#000000' }} />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {testDtos.map((item) => {
              numTest += 1;
              return (
                <div>
                  <DialogTestItem
                    isEdit={isEdit === true}
                    num={numTest}
                    key={numTest}
                    keyTitle={dto?.key ?? ''}
                    titleName={dto?.title ?? ''}
                    dto={item}
                  />
                  <Divider />
                </div>
              );
            })}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogTest.defaultProps = {
  isEdit: false,
  dto: undefined,
};
