import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { ContentDto } from '../../dto/ContentDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiDeleteContent } from '../../service/apiDeleteContent';
import {
  API_STATUS_PROBLEM,
  DESIGN_FRONT_TYPE,
} from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { AddFile } from '../add-file/AddFile';
import { BASE_URL } from '../../setting/AppSettings';
import { UpdateAccentColorDto } from '../../dto/UpdateAccentColorDto';
import { apiUpdateColor } from '../../service/apiUpdateColor';
import { MyTextFiled } from '../UI/MyTextField';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: ContentDto;
}

export const DialogDesign: FunctionComponent<Props> = ({
  open,
  dto,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [isDelete, setIsDelete] = useState(false);

  const { section } = useParams();

  const [updateColorDto, setUpdateColorDto] = useState<UpdateAccentColorDto>({
    color: dto?.appearance.accentColor ?? '',
    key: dto?.key ?? '',
    colorDark: dto?.appearance.accentColorDark ?? '',
  });

  const deleteContent = async (route: string, type: number, key: string) => {
    const apiResult: IApiResult = await apiDeleteContent(route, type, key);
    if (apiResult.isSuccess) {
      setIsDelete(true);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleClose = () => {
    onClose();
    setUpdateColorDto({
      color: '',
      key: '',
      colorDark: '',
    });
  };

  const updateColor = async (route: string) => {
    const apiResult: IApiResult = await apiUpdateColor(route, updateColorDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      setUpdateColorDto({
        color: dto?.appearance.accentColor ?? '',
        key: dto?.key ?? '',
        colorDark: dto?.appearance.accentColorDark ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%', height: '100%' },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '24px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`Дизайн - ${dto?.key} ${dto?.title}`}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                <AddFile
                  name="fileToUpload"
                  icon={
                    <AddBoxIcon fontSize="large" sx={{ color: 'purple' }} />
                  }
                  typeContent={DESIGN_FRONT_TYPE}
                  dto={dto}
                />
                <IconButton
                  title="Удалить"
                  size="small"
                  onClick={() =>
                    deleteContent(
                      section ?? '',
                      DESIGN_FRONT_TYPE,
                      dto?.key ?? '-11111'
                    )
                  }
                >
                  <DeleteIcon fontSize="large" sx={{ color: 'red' }} />
                </IconButton>
              </Stack>
              <IconButton title="Закрыть" size="small" onClick={handleClose}>
                <CloseIcon fontSize="large" sx={{ color: '#000000' }} />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack alignItems="center" spacing={2}>
            {!isDelete && (
              <img
                alt={dto?.appearance.frontImage}
                src={`${BASE_URL}/design/${dto?.appearance.frontImage}`}
                width={250}
                style={{ cursor: 'pointer' }}
              />
            )}
            {!isDelete && (
              <Stack direction="row" spacing={1}>
                <MyTextFiled
                  value={updateColorDto.color}
                  size="small"
                  onChange={(e) =>
                    setUpdateColorDto({
                      ...updateColorDto,
                      color: e.target.value,
                    })
                  }
                  label="Color"
                />
                <MyTextFiled
                  value={updateColorDto.colorDark}
                  size="small"
                  onChange={(e) =>
                    setUpdateColorDto({
                      ...updateColorDto,
                      colorDark: e.target.value,
                    })
                  }
                  label="ColorDark"
                />
                <Stack direction="row" spacing={1}>
                  <IconButton
                    title="Сохранить"
                    size="small"
                    onClick={() => updateColor(section ?? '')}
                  >
                    <SaveIcon fontSize="large" sx={{ color: 'blue' }} />
                  </IconButton>
                </Stack>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogDesign.defaultProps = {
  dto: undefined,
};
