import React from 'react';
import { TridDiv } from '../component/3d/TridDiv';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const TridPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<TridDiv />}
  />
);
