import React from 'react';
import { PurchaseDiv } from '../component/purchase/PurchaseDiv';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const PurchasePage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<PurchaseDiv />}
  />
);
