import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton, Stack } from '@mui/material';
import { Update3dDto } from '../../dto/Update3dDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiUpdateTrid } from '../../service/apiUpdateTrid';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

interface Props {
  imageContent: any;
  type: number;
  keyTitle: string;
  value: number;
  tooltip: string;
  onCloseEvent: () => void;
}

export const TridContentItemDiv: FunctionComponent<Props> = ({
  imageContent,
  type,
  keyTitle,
  value,
  tooltip,
  onCloseEvent,
}) => {
  const [updateDto] = useState<Update3dDto>({
    key: keyTitle,
    type,
    value,
  });
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { section } = useParams();
  const updateTrid = async (route: string) => {
    const apiResult: IApiResult = await apiUpdateTrid(route, updateDto);
    if (apiResult.isSuccess) {
      onCloseEvent();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleClick = () => {
    updateTrid(section ?? '');
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack alignItems="center" justifyContent="center">
        <IconButton title={tooltip} size="medium" onClick={handleClick}>
          {imageContent}
        </IconButton>
      </Stack>
    </div>
  );
};
