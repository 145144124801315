import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AddTaskIcon from '@mui/icons-material/AddTask';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ArticleIcon from '@mui/icons-material/Article';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import GppGoodIcon from '@mui/icons-material/GppGood';
import ImageIcon from '@mui/icons-material/Image';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import TelegramIcon from '@mui/icons-material/Telegram';
import {
  DESIGN_ROUTE,
  MAIN_ROUTE,
  MESSAGES_ROUTE,
  RELEASE_ROUTE,
  SEARCH_ROUTE,
  SHOPS_ROUTE,
  STATS_ROUTE,
  TASK_ROUTE,
  TRID_ROUTE,
} from '../../constant/routes';
import { MAIN_COLOR_STRING } from '../../constant/constants';

const actions = [
  { icon: <ArticleIcon />, name: '2D', route: 'main', tooltip: 'Структура' },
  {
    icon: <ThreeDRotationIcon />,
    name: '3D',
    route: '3d',
    tooltip: 'Управление 3D',
  },
  {
    icon: <ImageIcon />,
    name: 'Изображения',
    route: 'images',
    tooltip: 'Поиск и атлас',
  },
  // { icon: <ShoppingCartIcon />, name: 'Покупки', route: 'shop' },
  {
    icon: <DesignServicesIcon />,
    name: 'Дизайн',
    route: 'design',
    tooltip: 'Дизайн',
  },
  {
    icon: <TelegramIcon />,
    name: 'Телеграм-Бот',
    route: 'telegram',
    tooltip: 'Сообщения пользователям',
  },
  {
    icon: <AddTaskIcon />,
    name: 'Управление задачами',
    route: 'task',
    tooltip: 'Управление задачами',
  },
  {
    icon: <GppGoodIcon />,
    name: 'Релиз',
    route: 'reliz',
    tooltip: 'Проверка и релиз',
  },
  // { icon: <QueryStatsIcon />, name: 'Статистика', route: 'stats', tooltip: '2D конспекты' },
];

export const SpeedDialDiv = () => {
  const navigate = useNavigate();
  const { section } = useParams();
  const getImage = () => {
    if (window.location.pathname.includes(MAIN_ROUTE)) {
      return <ArticleIcon />;
    }
    if (window.location.pathname.includes(SEARCH_ROUTE)) {
      return <ImageIcon />;
    }
    if (window.location.pathname.includes(SHOPS_ROUTE)) {
      return <ShoppingCartIcon />;
    }
    if (window.location.pathname.includes(TRID_ROUTE)) {
      return <ThreeDRotationIcon />;
    }
    if (window.location.pathname.includes(DESIGN_ROUTE)) {
      return <DesignServicesIcon />;
    }
    if (window.location.pathname.includes(TASK_ROUTE)) {
      return <AddTaskIcon />;
    }
    if (window.location.pathname.includes(RELEASE_ROUTE)) {
      return <GppGoodIcon />;
    }
    if (window.location.pathname.includes(MESSAGES_ROUTE)) {
      return <TelegramIcon />;
    }
    if (window.location.pathname.includes(STATS_ROUTE)) {
      return <QueryStatsIcon />;
    }
    return <ArticleIcon />;
  };
  const [selectedImage, setSelectedImage] = useState(getImage);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = (image: any, name: string) => {
    switch (name) {
      case '2D':
        navigate(`${MAIN_ROUTE}/${section}`);
        break;
      case 'Изображения':
        navigate(`${SEARCH_ROUTE}/${section}`);
        break;
      case 'Покупки':
        navigate(`${SHOPS_ROUTE}/${section}`);
        break;
      case '3D':
        navigate(`${TRID_ROUTE}/${section}`);
        break;
      case 'Дизайн':
        navigate(`${DESIGN_ROUTE}/${section}`);
        break;
      case 'Управление задачами':
        navigate(`${TASK_ROUTE}/${section}`);
        break;
      case 'Релиз':
        navigate(`${RELEASE_ROUTE}/${section}`);
        break;
      case 'Телеграм-Бот':
        navigate(`${MESSAGES_ROUTE}/${section}`);
        break;
      case 'Статистика':
        navigate(`${STATS_ROUTE}/${section}`);
        break;
      default:
        navigate(`${MAIN_ROUTE}/${section}`);
        break;
    }
    setSelectedImage(image);
    setOpen(false);
  };

  return (
    <Box
      sx={{
        height: 320,
        transform: 'translateZ(0px)',
        flexGrow: 1,
        color: MAIN_COLOR_STRING,
      }}
    >
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{
          position: 'absolute',
          bottom: 16,
          right: 16,
        }}
        icon={selectedImage}
        onClick={handleOpen}
        open={open}
        FabProps={{
          sx: {
            bgcolor: '#9c27b0',
            '&:hover': {
              bgcolor: '#9c27b0',
            },
          },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.tooltip}
            onClick={() => handleClose(action.icon, action.name)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};
