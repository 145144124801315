import React, { FunctionComponent } from 'react';
import { Button } from '@mui/material';

interface Props {
  text: string;
  disabled?: boolean;
  variant?: any;
  width?: string;
  height?: string;
  background: string;
  color?: string;
  onClick?: () => void;
}

export const MyButton: FunctionComponent<Props> = ({
  text,
  disabled,
  width,
  variant,
  height,
  background,
  color,
  onClick,
}) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    variant={variant}
    sx={{
      background,
      ':hover': {
        background,
      },
      ':focus': {
        background,
      },
    }}
    style={{
      fontFamily: 'sans-serif',
      fontSize: '16px',
      textTransform: 'none',
      width,
      height,
      color,
    }}
  >
    {text}
  </Button>
);

MyButton.defaultProps = {
  width: '100%',
  disabled: false,
  height: '50px',
  variant: 'contained',
  color: '#FFFFFF',
  onClick: () => undefined,
};
