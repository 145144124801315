import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../constant/constants';
import { UpdateAccentColorDto } from '../dto/UpdateAccentColorDto';
import { IApiParams } from '../interface/IApiParams';
import { getCookie, getLang, secureToken } from '../utils/utils';
import { getApi } from './getApi';

export const apiUpdateColor = async (
  route: string,
  dto: UpdateAccentColorDto
) => {
  const params: IApiParams = {
    url: `/updateAccentColor.php?baseName=${route}&${secureToken()}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': getCookie(ACCESS_TOKEN_NAME),
      'Api-Lang': getLang(),
    },
  };
  return getApi(params, 'POST', dto);
};
