import React from 'react';
import { ReleaseDiv } from '../component/release/ReleaseDiv';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const ReleasePage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<ReleaseDiv />}
  />
);
