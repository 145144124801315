import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../constant/constants';
import { CaseDto } from '../dto/CaseDto';
import { IApiParams } from '../interface/IApiParams';
import { getCookie, getLang, secureToken } from '../utils/utils';
import { getApi } from './getApi';

export const apiSaveCase = async (
  route: string,
  key: string,
  title: string,
  dto?: CaseDto
) => {
  const params: IApiParams = {
    url: `/saveCase.php?baseName=${route}&key=${key}&title=${title}&${secureToken()}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': getCookie(ACCESS_TOKEN_NAME),
      'Api-Lang': getLang(),
    },
  };
  return getApi(params, 'POST', dto);
};
