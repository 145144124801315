import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';
import { MessagesDiv } from '../component/messages/MessagesDiv';

export const MessagesPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<MessagesDiv />}
  />
);
