import React, { FunctionComponent } from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import ImagesearchRollerIcon from '@mui/icons-material/ImagesearchRoller';
import { Stack, Typography } from '@mui/material';
import { ContentDto } from '../../dto/ContentDto';
import {
  getFontSize,
  getFontWeight,
  getKeyLevel,
  getPadding,
} from '../../utils/utils';
import { DesignContentItemDiv } from './DesignContentItemDiv';
import { BASE_URL } from '../../setting/AppSettings';

interface Props {
  dto?: ContentDto;
  isEdit?: boolean;
  onClick?: () => void;
  onCloseEvent?: () => void;
}

export const DesignRowSectionDivItem: FunctionComponent<Props> = ({
  dto,
  isEdit,
  onClick,
  onCloseEvent,
}) => {
  const level = getKeyLevel(dto?.key);
  const fontWeightNew = getFontWeight(level);
  const fontSizeNew = getFontSize(level);

  const handleCloseTwo = () => {
    if (onCloseEvent !== undefined) {
      onCloseEvent();
    }
  };

  const padding = getPadding(level);
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          bgcolor: '#FFFFFF',
          '&:hover': {
            bgcolor: '#eeeeee',
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          onClick={isEdit ? onClick : undefined}
          justifyContent="center"
        >
          <Stack direction="row" width="20px">
            {dto?.isFree ? undefined : (
              <AttachMoneyIcon sx={{ color: 'green' }} />
            )}
            {dto?.isAuth ? <PersonIcon sx={{ color: 'orange' }} /> : undefined}
          </Stack>
          <Stack paddingLeft={padding}>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: fontWeightNew,
                fontSize: fontSizeNew,
                color: '#000000',
              }}
              component="text"
            >
              {`${dto?.key} ${dto?.title}`}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <img
            alt={dto?.appearance.frontImage}
            src={`${BASE_URL}/design/${dto?.appearance.frontImage}`}
            width={100}
            style={{ cursor: 'pointer' }}
          />
          <DesignContentItemDiv
            imageContent={
              <ImagesearchRollerIcon
                sx={{
                  color:
                    dto?.appearance.accentColor !== ''
                      ? dto?.appearance.accentColor
                      : '#e0e0e0',
                }}
                width="20"
              />
            }
            onCloseEvent={handleCloseTwo}
            dto={dto}
          />
        </Stack>
      </Stack>
    </div>
  );
};

DesignRowSectionDivItem.defaultProps = {
  dto: undefined,
  isEdit: false,
  onClick: undefined,
  onCloseEvent: undefined,
};
