export const SIGN_IN_ROUTE = '/';
export const MAIN_ROUTE = '/main';
export const SEARCH_ROUTE = '/images';
export const SHOPS_ROUTE = '/shops';
export const EDIT_ROUTE = '/edit';
export const TRID_ROUTE = '/3d';
export const DESIGN_ROUTE = '/design';
export const MESSAGES_ROUTE = '/message';
export const RELEASE_ROUTE = '/release';
export const STATS_ROUTE = '/stats';
export const TASK_ROUTE = '/task';
