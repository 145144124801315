import React, { FunctionComponent } from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PersonIcon from '@mui/icons-material/Person';
import TelegramIcon from '@mui/icons-material/Telegram';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import QuizIcon from '@mui/icons-material/Quiz';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { Stack, Typography } from '@mui/material';
import { ContentDto } from '../../dto/ContentDto';
import {
  getFontSize,
  getFontWeight,
  getKeyLevel,
  getPadding,
} from '../../utils/utils';
import {
  ROW_SECTION_3D_ICON_COLOR,
  TRID_ABSTRACT_EXTENDED_TYPE,
  TRID_ABSTRACT_TYPE,
  TRID_ATLAS_PLUS_TYPE,
  TRID_ATLAS_TYPE,
  TRID_TEST_TYPE,
  TRID_VOICE_TYPE,
} from '../../constant/constants';
import { TridContentItemDiv } from './TridContentItemDiv';

interface Props {
  dto?: ContentDto;
  isEdit?: boolean;
  onClick?: () => void;
  onCloseEvent?: () => void;
}

export const TridRowSectionDivItem: FunctionComponent<Props> = ({
  dto,
  isEdit,
  onClick,
  onCloseEvent,
}) => {
  const level = getKeyLevel(dto?.key);
  const fontWeightNew = getFontWeight(level);
  const fontSizeNew = getFontSize(level);

  const handleCloseTwo = () => {
    if (onCloseEvent !== undefined) {
      onCloseEvent();
    }
  };

  const padding = getPadding(level);
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          bgcolor: '#FFFFFF',
          '&:hover': {
            bgcolor: '#eeeeee',
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          onClick={isEdit ? onClick : undefined}
          justifyContent="center"
        >
          <Stack direction="row" width="20px">
            {dto?.isFree ? undefined : (
              <AttachMoneyIcon sx={{ color: '#009688' }} />
            )}
            {dto?.isAuth ? <PersonIcon sx={{ color: '#ff9800' }} /> : undefined}
            {dto?.isTelegramPairing ? (
              <TelegramIcon sx={{ color: '#03a9f4' }} />
            ) : undefined}
          </Stack>
          <Stack paddingLeft={padding}>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: fontWeightNew,
                fontSize: fontSizeNew,
                color: '#000000',
              }}
              component="text"
            >
              {`${dto?.key} ${dto?.title}`}
            </Typography>
          </Stack>
        </Stack>
        {level !== 1 && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <TridContentItemDiv
              tooltip="3D конспект"
              imageContent={
                <TextSnippetIcon
                  sx={{
                    color: dto?.has3dAbstract
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              value={dto?.has3dAbstract ? 0 : 1}
              keyTitle={dto?.key ?? ''}
              type={TRID_ABSTRACT_TYPE}
            />
            <TridContentItemDiv
              tooltip="3D конспект плюс"
              imageContent={
                <NoteAddIcon
                  sx={{
                    color: dto?.has3DAbstractExtended
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              value={dto?.has3DAbstractExtended ? 0 : 1}
              keyTitle={dto?.key ?? ''}
              type={TRID_ABSTRACT_EXTENDED_TYPE}
            />
            <TridContentItemDiv
              tooltip="3D атлас"
              imageContent={
                <ImageSearchIcon
                  sx={{
                    color: dto?.has3dAtlas
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              keyTitle={dto?.key ?? ''}
              value={dto?.has3dAtlas ? 0 : 1}
              type={TRID_ATLAS_TYPE}
            />
            <TridContentItemDiv
              tooltip="3D атлас плюс"
              imageContent={
                <AddPhotoAlternateIcon
                  sx={{
                    color: dto?.atlasPlusEnabled
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              keyTitle={dto?.key ?? ''}
              value={dto?.atlasPlusEnabled ? 0 : 1}
              type={TRID_ATLAS_PLUS_TYPE}
            />
            <TridContentItemDiv
              tooltip="3D тесты"
              imageContent={
                <QuizIcon
                  sx={{
                    color: dto?.has3dTest
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              keyTitle={dto?.key ?? ''}
              value={dto?.has3dTest ? 0 : 1}
              type={TRID_TEST_TYPE}
            />
            <TridContentItemDiv
              tooltip="3D репетитор"
              imageContent={
                <RecordVoiceOverIcon
                  sx={{
                    color: dto?.has3dVoice
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              value={dto?.has3dVoice ? 0 : 1}
              keyTitle={dto?.key ?? ''}
              type={TRID_VOICE_TYPE}
            />
          </Stack>
        )}
      </Stack>
    </div>
  );
};

TridRowSectionDivItem.defaultProps = {
  dto: undefined,
  isEdit: false,
  onClick: undefined,
  onCloseEvent: undefined,
};
