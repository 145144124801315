import React, { FunctionComponent, useState } from 'react';
import FileDownload from 'js-file-download';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { ContentDto } from '../../dto/ContentDto';
import { DialogVideoItem } from './DialogVideoItem';
import { IApiResult } from '../../interface/IApiResult';
import { apiDeleteContent } from '../../service/apiDeleteContent';
import { API_STATUS_PROBLEM, VIDEO_TYPE } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { AddFile } from '../add-file/AddFile';

interface Props {
  open: boolean;
  isEdit?: boolean;
  onClose: () => void;
  dto?: ContentDto;
}

export const DialogVideo: FunctionComponent<Props> = ({
  open,
  dto,
  isEdit,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [isDelete, setIsDelete] = useState(false);

  let numItem = 0;

  const { section } = useParams();

  const deleteContent = async (route: string, type: number, key: string) => {
    const apiResult: IApiResult = await apiDeleteContent(route, type, key);
    if (apiResult.isSuccess) {
      setIsDelete(true);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const getVideo = async (route: string) => {
    FileDownload(
      JSON.stringify(dto?.video),
      `${route}_видео_${dto?.key}_${dto?.title}.txt` ?? ''
    );
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%', height: '100%' },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '24px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`Видео - ${dto?.key} ${dto?.title}`}
            </Typography>
            <Stack direction="row" spacing={1}>
              {!isEdit && (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    title="Скачать"
                    size="small"
                    onClick={() => getVideo(section ?? '')}
                  >
                    <DownloadIcon fontSize="large" sx={{ color: '#9c27b0' }} />
                  </IconButton>
                </Stack>
              )}
              {isEdit && (
                <Stack direction="row" spacing={1}>
                  <AddFile
                    name="fileToUpload"
                    icon={
                      <FileUploadIcon
                        fontSize="large"
                        sx={{ color: '#9c27b0' }}
                      />
                    }
                    typeContent={VIDEO_TYPE}
                    dto={dto}
                  />
                  <IconButton
                    title="Удалить"
                    size="small"
                    onClick={() =>
                      deleteContent(
                        section ?? '',
                        VIDEO_TYPE,
                        dto?.key ?? '-11111'
                      )
                    }
                  >
                    <DeleteIcon fontSize="large" sx={{ color: 'red' }} />
                  </IconButton>
                </Stack>
              )}
              <IconButton title="Закрыть" size="small" onClick={handleClose}>
                <CloseIcon fontSize="large" sx={{ color: '#000000' }} />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {!isDelete && (
            <Stack spacing={2}>
              {dto?.video.map((item) => {
                numItem += 1;
                return (
                  <div>
                    <DialogVideoItem num={numItem} key={numItem} dto={item} />
                    <Divider />
                  </div>
                );
              })}
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogVideo.defaultProps = {
  isEdit: false,
  dto: undefined,
};
