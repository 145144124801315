import React, { FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';
import { VideoDto } from '../../dto/ContentDto';

interface Props {
  num: number;
  dto?: VideoDto;
}

export const DialogVideoItem: FunctionComponent<Props> = ({ num, dto }) => {
  let linkVideo = dto?.link;
  if (dto?.type === 'youtube') {
    linkVideo = `https://www.youtube-nocookie.com/embed/${dto?.link}`;
  }
  return (
    <div>
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 600,
            width: '100%',
            fontSize: '18px',
            color: '#000000',
            letterSpacing: 1,
          }}
          component="text"
        >
          {`Видео ${num}`}
        </Typography>
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            width: '100%',
            fontSize: '18px',
            color: '#000000',
            letterSpacing: 1,
          }}
          component="text"
        >
          {`${dto?.name}\n${dto?.link}`}
        </Typography>
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            width: '100%',
            fontSize: '18px',
            color: dto?.type === 'kinescope' ? 'green' : 'red',
            letterSpacing: 1,
          }}
          component="text"
        >
          {`${dto?.type}`}
        </Typography>
        <div
          style={{
            borderRadius: '10px 10px 10px 10px',
            overflow: 'hidden',
            width: '100%',
            display: 'flex',
            height: 'fit-content',
          }}
        >
          <iframe
            src={linkVideo}
            height="500px"
            width="100%"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={dto?.name}
          />
        </div>
      </Stack>
    </div>
  );
};

DialogVideoItem.defaultProps = {
  dto: undefined,
};
