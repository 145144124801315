import React, { useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyTextFiled } from '../UI/MyTextField';
import { AddPushDto } from '../../dto/AddPushDto';
import { apiAddPush } from '../../service/apiAddPush';

export const PushDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [backDrop, setBackDrop] = useState(false);

  const [addPushDto, setAddPushDto] = useState<AddPushDto>({
    title: '',
    message: '',
  });

  const addPush = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiAddPush(addPushDto);
    if (apiResult.isSuccess) {
      setAddPushDto({
        ...addPushDto,
        title: '',
        message: '',
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" spacing={1}>
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        <Stack>
          <Stack direction="row" spacing={1}>
            <Stack width="100%" spacing={1}>
              <MyTextFiled
                value={addPushDto?.title}
                size="small"
                onChange={(e) =>
                  setAddPushDto({
                    ...addPushDto,
                    title: e.target.value,
                  })
                }
                multiLine
                label="Заголовок"
              />
              <MyTextFiled
                value={addPushDto?.message}
                size="small"
                onChange={(e) =>
                  setAddPushDto({
                    ...addPushDto,
                    message: e.target.value,
                  })
                }
                multiLine
                label="Сообщение"
              />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                size="large"
                disabled={addPushDto.title === '' || addPushDto.message === ''}
                onClick={() => addPush()}
              >
                <SendIcon fontSize="large" sx={{ color: 'blue' }} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
