import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { TaskDto } from '../../dto/TaskDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetTasks } from '../../service/apiGetTasks';
import { API_STATUS_PROBLEM, SECTION_NAME } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { MyAlert } from '../UI/MyAlert';
import { apiTaskDone } from '../../service/apiTaskDone';

export const CorrectTaskDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [taskDto, setTaskDto] = useState<TaskDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);
  const getTasks = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetTasks();
    if (apiResult.isSuccess) {
      setTaskDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const taskDone = async (dto: TaskDto) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiTaskDone(dto);
    if (apiResult.isSuccess) {
      getTasks();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    getTasks();
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'datetime',
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Дата и время
        </Typography>
      ),
      width: 180,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.datetime}
        </Typography>
      ),
    },
    {
      field: 'route',
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Предмет
        </Typography>
      ),
      width: 150,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.route}
        </Typography>
      ),
    },
    {
      field: 'key',
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Раздел
        </Typography>
      ),
      width: 100,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.key}
        </Typography>
      ),
    },
    {
      field: 'title',
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Наименование
        </Typography>
      ),
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.title}
        </Typography>
      ),
    },
    {
      field: 'message',
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Сообщение
        </Typography>
      ),
      flex: 1.5,
      minWidth: 350,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.message}
        </Typography>
      ),
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Статус"
            size="small"
            onClick={() => taskDone(row.row)}
          >
            <CheckCircleIcon
              fontSize="small"
              sx={{ color: row.row.status === 0 ? '#e0e0e0' : '#9c27b0' }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="90%" padding="10px" spacing={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 1000,
              fontSize: '30px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`${localStorage.getItem(SECTION_NAME)} - Управление задачами`}
          </Typography>
        </Stack>
        <Divider />
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                  py: 1,
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: '5px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                  py: '10px',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                  {
                    outline: 'none !important',
                  },
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              rows={taskDto}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...other}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </ThemeProvider>
        </Box>
      </Stack>
    </Stack>
  );
};
