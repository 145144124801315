import React, { FunctionComponent, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { useParams } from 'react-router-dom';
import { IconButton, Stack, Typography } from '@mui/material';
import { TestImgDto } from '../../dto/TestImgDto';
import { BASE_URL } from '../../setting/AppSettings';
import { IApiResult } from '../../interface/IApiResult';
import { apiSaveTestImg } from '../../service/apiSaveTestImg';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { MyTextFiled } from '../UI/MyTextField';

interface Props {
  num: number;
  isEdit: boolean;
  keyTitle: string;
  titleName: string;
  dto?: TestImgDto;
}

export const DialogTestImgItem: FunctionComponent<Props> = ({
  num,
  isEdit,
  keyTitle,
  titleName,
  dto,
}) => {
  const { section } = useParams();
  const [testDto, setTestDto] = useState(dto);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const saveText = async (route: string, key: string, title: string) => {
    const apiResult: IApiResult = await apiSaveTestImg(
      route,
      key,
      title,
      testDto
    );
    if (apiResult.isSuccess) {
      setAlertProps({
        message: 'Данные записаны',
        severity: AlertSeverityEnum.info,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const setArrayOprions = (value: string, index: number) => {
    const array = testDto?.options;
    if (array !== undefined && testDto !== undefined) {
      array[index] = value;
      setTestDto({ ...testDto, options: array });
    }
  };

  const setArrayCorrectOprions = (value: string, index: number) => {
    const array = testDto?.correctOptions;
    if (array !== undefined && testDto !== undefined) {
      array[index] = value;
      setTestDto({ ...testDto, correctOptions: array });
    }
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <Stack direction="row" width="100%" alignItems="center" spacing={1}>
          {isEdit && (
            <Stack direction="row" spacing={1}>
              <IconButton
                title="Сохранить"
                size="small"
                onClick={() => saveText(section ?? '', keyTitle, titleName)}
              >
                <SaveIcon fontSize="large" sx={{ color: '#2196f3' }} />
              </IconButton>
            </Stack>
          )}
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              width: '100%',
              fontSize: '18px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`Вопрос ${num} - ID в базе ${dto?.id}`}
          </Typography>
        </Stack>
        <Stack spacing={1} width="100%">
          {!isEdit && (
            <img
              alt={testDto?.image}
              src={`${BASE_URL}/${section}/image_webp/webp_${testDto?.image}.webp`}
              width={200}
              style={{ cursor: 'pointer' }}
            />
          )}
          {isEdit && (
            <MyTextFiled
              value={testDto?.image}
              multiLine
              onChange={(e) =>
                testDto !== undefined &&
                setTestDto({ ...testDto, image: e.target.value })
              }
              size="small"
              label="Вопрос"
            />
          )}
        </Stack>
        {testDto?.options.map((item, index) => (
          <Stack spacing={1} width="100%">
            {!isEdit && (
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#000000',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {item}
              </Typography>
            )}
            {isEdit && (
              <MyTextFiled
                value={item}
                multiLine
                onChange={(e) => setArrayOprions(e.target.value, index)}
                size="small"
                label="Ответ"
              />
            )}
          </Stack>
        ))}
        {testDto?.correctOptions.map((item, index) => (
          <Stack spacing={1} width="100%">
            {!isEdit && (
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#4caf50',
                  letterSpacing: 1,
                }}
                component="text"
              >
                {item}
              </Typography>
            )}
            {isEdit && (
              <MyTextFiled
                value={item}
                multiLine
                onChange={(e) => setArrayCorrectOprions(e.target.value, index)}
                size="small"
                label="Правильный ответ"
              />
            )}
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

DialogTestImgItem.defaultProps = {
  dto: undefined,
};
