import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Stack, Typography } from '@mui/material';
import { SectionDto } from '../../dto/SectionDto';
import { BASE_URL } from '../../setting/AppSettings';
import { MAIN_ROUTE } from '../../constant/routes';
import { SECTION_NAME, TRID_NAME } from '../../constant/constants';

interface Props {
  dto?: SectionDto;
}

export const SectionItemDiv: FunctionComponent<Props> = ({ dto }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    localStorage.setItem(SECTION_NAME, dto?.name ?? '');
    localStorage.setItem(TRID_NAME, dto?.has3d ? '1' : '0');
    navigate(`${MAIN_ROUTE}/${dto?.route}`);
  };
  return (
    <div>
      <Card
        style={{ height: '300px', cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Stack height="100%" alignItems="center" justifyContent="space-between">
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '16px',
              color: '#000000',
            }}
            component="text"
          >
            {dto?.author}
          </Typography>
          <img
            alt={dto?.route}
            src={`${BASE_URL}/design/${dto?.promoImage}`}
            width="60%"
            style={{ cursor: 'pointer' }}
          />
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '24px',
              color: '#000000',
            }}
            component="text"
          >
            {dto?.name}
          </Typography>
        </Stack>
      </Card>
    </div>
  );
};

SectionItemDiv.defaultProps = {
  dto: undefined,
};
