import { LANG_NAME, RUS_LANG, SECRET_KEY_ADMIN } from '../constant/constants';
import { CribDto } from '../dto/CribDto';
import { AlertSeverityEnum } from '../enum/AlertSeverityEnum';
import { IAlertProps } from '../interface/IAlertProps';
import { IApiResult } from '../interface/IApiResult';

export const GetCridDtoFromString = (idCrib: number, arra: string[]) => {
  const cribDto: CribDto[] = [];
  for (let i = 0; i < arra.length; i += 1) {
    cribDto.push({
      id: `${idCrib}_${i}`,
      crib: arra[i],
    });
  }
  return cribDto;
};

export const isMobileView = () => {
  const result = window.innerWidth < 820;
  return result;
};

export const getDefaultAlertProps = () => {
  const alertApp: IAlertProps = {
    message: '',
    severity: AlertSeverityEnum.error,
  };
  return alertApp;
};

export const getDefaultApiResult = () => {
  const apiResult: IApiResult = {
    isSuccess: false,
    status: -1,
    data: null,
    alert: getDefaultAlertProps(),
  };
  return apiResult;
};

export const setToken = (tokenType: string) =>
  `${localStorage.getItem(tokenType)}`;

export const getSimvol = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const secureToken = () => {
  const expires = Math.floor(Date.now() / 1000) + 10;
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const md5 = require('md5');
  const crc = md5(`${SECRET_KEY_ADMIN}${expires}`);
  const url = `expires=${expires}&crc=${crc}`;
  return url;
};

export const getCookie = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\\.$?*|{}\\(\\)\\[\]\\\\/\\+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const getLang = () => {
  const lang =
    localStorage.getItem(LANG_NAME) === null
      ? RUS_LANG
      : localStorage.getItem(LANG_NAME);
  return lang;
};

export const getKeyLevel = (key?: string) => {
  if (key === undefined) {
    return 0;
  }
  const arrayLevel = key.split('.');
  return arrayLevel.length;
};

export const getFontWeight = (level: number) => {
  switch (level) {
    case 1:
      return 900;
    case 2:
      return 700;
    case 3:
      return 500;
    case 4:
      return 300;
    case 5:
      return 100;
    default:
      return 100;
  }
};

export const getFontSize = (level: number) => {
  switch (level) {
    case 1:
      return '24px';
    case 2:
      return '20px';
    case 3:
      return '18px';
    case 4:
      return '16px';
    case 5:
      return '14px';
    default:
      return '14px';
  }
};

export const getPadding = (level: number) => {
  switch (level) {
    case 1:
      return '0px';
    case 2:
      return '25px';
    case 3:
      return '50px';
    case 4:
      return '75px';
    case 5:
      return '100px';
    default:
      return '0px';
  }
};
