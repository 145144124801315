import React from 'react';
import { SearchDiv } from '../component/search/SearchDiv';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';

export const SearchPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<SearchDiv />}
  />
);
