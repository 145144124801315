import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { TelegramDto } from '../../dto/TelegramDto';
import { apiGetTelegramBotUsers } from '../../service/telegram/apiGetTelegramBotUsers';
import { apiSendTelegramMessage } from '../../service/telegram/apiSendTelegramMessage';
import { MyTextFiled } from '../UI/MyTextField';
import { TelegramMessageDto } from '../../dto/TelegramMessageDto';
import { RequestDto } from '../../dto/RequestDto';
import { apiGetRequest } from '../../service/telegram/apiGetRequest';
import { apiCloseRequest } from '../../service/telegram/apiCloseRequest';
import { UpdateRequestDto } from '../../dto/UpdateRequestDto';

export const TelegramDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [telegramDtos, setTelegramDtos] = useState<TelegramDto[]>([]);
  const [requestDtos, setRequestDtos] = useState<RequestDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const [telegramMessageDto, setTelegramMessageDto] =
    useState<TelegramMessageDto>({
      message: '',
    });

  const getTelegramBotUsers = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetTelegramBotUsers();
    if (apiResult.isSuccess) {
      setTelegramDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getRequest = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetRequest();
    if (apiResult.isSuccess) {
      setRequestDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const sendTelegramMessage = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiSendTelegramMessage(
      telegramMessageDto
    );
    if (apiResult.isSuccess) {
      setTelegramMessageDto({
        ...telegramMessageDto,
        message: '',
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const endRequest = async (dto: RequestDto) => {
    setBackDrop(true);
    const updateRequestDto: UpdateRequestDto = {
      id: dto.id,
      telegramId: dto.telegramId,
    };
    const apiResult: IApiResult = await apiCloseRequest(updateRequestDto);
    if (apiResult.isSuccess) {
      getRequest();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    if (valueTab === 0) {
      getTelegramBotUsers();
    }
    if (valueTab === 1) {
      getRequest();
    }
  }, [valueTab]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'telegramFirstName',
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Пользователь Telegram
        </Typography>
      ),
      flex: 1,
      minWidth: 350,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.telegramFirstName}
        </Typography>
      ),
    },
    {
      field: 'appUserName',
      width: 200,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Пользователь Учи Мед
        </Typography>
      ),
      flex: 1,
      minWidth: 350,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.appUserName}
        </Typography>
      ),
    },
  ];

  const columnsRequest: GridColDef[] = [
    {
      field: 'datetime',
      width: 350,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Заявка
        </Typography>
      ),
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {`№ ${row.row.id} от ${row.row.datetime}`}
        </Typography>
      ),
    },
    {
      field: 'requestType',
      width: 200,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Тип заявки
        </Typography>
      ),
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.requestType}
        </Typography>
      ),
    },
    {
      field: 'message',
      width: 200,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Сообщение
        </Typography>
      ),
      flex: 1,
      minWidth: 350,
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.message}
        </Typography>
      ),
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Закрыть"
            size="small"
            onClick={() => endRequest(row.row)}
          >
            <CloseIcon fontSize="small" sx={{ color: 'red' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" spacing={0}>
        <Stack width="90%" direction="row" justifyContent="space-between">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab label="Пользователи" />
              <Tab label="Заявки" />
              <Tab label="Рассылка" />
            </Tabs>
          </Box>
        </Stack>
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        {valueTab === 0 && (
          <Box sx={{ width: '100%' }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={columns}
                rows={telegramDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={20}
                rowsPerPageOptions={[20]}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        )}
        {valueTab === 1 && (
          <Box sx={{ width: '100%' }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={columnsRequest}
                rows={requestDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={20}
                rowsPerPageOptions={[20]}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        )}
        {valueTab === 2 && (
          <Stack>
            <Stack direction="row" spacing={1}>
              <MyTextFiled
                value={telegramMessageDto?.message}
                size="small"
                onChange={(e) =>
                  setTelegramMessageDto({
                    ...telegramMessageDto,
                    message: e.target.value,
                  })
                }
                multiLine
                label="Сообщение"
              />
              <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                  size="large"
                  disabled={telegramMessageDto.message === ''}
                  onClick={() => sendTelegramMessage()}
                >
                  <SendIcon fontSize="large" sx={{ color: 'blue' }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
