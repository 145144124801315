import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { MyTextFiled } from '../UI/MyTextField';
import { AddTitleDto } from '../../dto/AddTitleDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiAddTitle } from '../../service/apiAddTitle';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DialogAddTitleDiv: FunctionComponent<Props> = ({
  open,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { section } = useParams();
  const [addTitleDto, setAddTitleDto] = useState<AddTitleDto>({
    mainTitle: '',
    lvl1: '',
    lvl2: '',
    lvl3: '',
    lvl4: '',
    lvl5: '',
    title: '',
  });

  const handleClose = () => {
    onClose();
    setAddTitleDto({
      mainTitle: '',
      lvl1: '',
      lvl2: '',
      lvl3: '',
      lvl4: '',
      lvl5: '',
      title: '',
    });
  };

  const addTitle = async (route: string) => {
    if (
      addTitleDto.mainTitle === '' ||
      addTitleDto.lvl1 === '' ||
      addTitleDto.lvl2 === '' ||
      addTitleDto.lvl3 === '' ||
      addTitleDto.lvl4 === '' ||
      addTitleDto.lvl5 === '' ||
      addTitleDto.title === ''
    ) {
      return;
    }
    const apiResult: IApiResult = await apiAddTitle(route, addTitleDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%' },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '24px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              Новый раздел
            </Typography>
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                <IconButton
                  title="Сохранить"
                  size="small"
                  onClick={() => addTitle(section ?? '')}
                >
                  <SaveIcon fontSize="large" sx={{ color: 'blue' }} />
                </IconButton>
              </Stack>
              <IconButton title="Закрыть" size="small" onClick={handleClose}>
                <CloseIcon fontSize="large" sx={{ color: '#000000' }} />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <MyTextFiled
                value={addTitleDto?.lvl1}
                onChange={(e) =>
                  setAddTitleDto({
                    ...addTitleDto,
                    lvl1: e.target.value,
                    mainTitle: e.target.value,
                  })
                }
                size="small"
                label="Ур. 1"
              />
              <MyTextFiled
                value={addTitleDto?.lvl2}
                onChange={(e) =>
                  setAddTitleDto({ ...addTitleDto, lvl2: e.target.value })
                }
                size="small"
                label="Ур. 2"
              />
              <MyTextFiled
                value={addTitleDto?.lvl3}
                onChange={(e) =>
                  setAddTitleDto({ ...addTitleDto, lvl3: e.target.value })
                }
                size="small"
                label="Ур. 3"
              />
              <MyTextFiled
                value={addTitleDto?.lvl4}
                onChange={(e) =>
                  setAddTitleDto({ ...addTitleDto, lvl4: e.target.value })
                }
                size="small"
                label="Ур. 4"
              />
              <MyTextFiled
                value={addTitleDto?.lvl5}
                onChange={(e) =>
                  setAddTitleDto({ ...addTitleDto, lvl5: e.target.value })
                }
                size="small"
                label="Ур. 5"
              />
            </Stack>
            <MyTextFiled
              value={addTitleDto?.title}
              onChange={(e) =>
                setAddTitleDto({ ...addTitleDto, title: e.target.value })
              }
              size="small"
              label="Наименование"
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
