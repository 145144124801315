import React, { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PersonIcon from '@mui/icons-material/Person';
import TelegramIcon from '@mui/icons-material/Telegram';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import QuizIcon from '@mui/icons-material/Quiz';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import EditIcon from '@mui/icons-material/Edit';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as AbstarctImage } from '../../image/abstract.svg';
import { ReactComponent as PoolImage } from '../../image/pool.svg';
import { ReactComponent as TestImage } from '../../image/test.svg';
import { ReactComponent as TestImageImage } from '../../image/test-img.svg';
import { ReactComponent as CaseImage } from '../../image/case.svg';
import { ReactComponent as CribImage } from '../../image/crib.svg';
import { ReactComponent as VideoImage } from '../../image/video.svg';
import { ContentDto } from '../../dto/ContentDto';
import {
  getFontSize,
  getFontWeight,
  getKeyLevel,
  getPadding,
} from '../../utils/utils';
import { ContentItemDiv } from './ContentItemDiv';
import {
  ABSTRACT_TYPE,
  CASE_TYPE,
  CRIB_TYPE,
  POOL_TYPE,
  ROW_SECTION_3D_ICON_COLOR,
  ROW_SECTION_ICON_COLOR,
  TEST_IMAGE_TYPE,
  TEST_TYPE,
  TRID_ABSTRACT_EXTENDED_TYPE,
  TRID_ABSTRACT_TYPE,
  TRID_ATLAS_PLUS_TYPE,
  TRID_ATLAS_TYPE,
  TRID_TEST_TYPE,
  TRID_VOICE_TYPE,
  VIDEO_TYPE,
} from '../../constant/constants';
import { EDIT_ROUTE } from '../../constant/routes';
import { DialogAddTitleDiv } from '../edit/DialogAddTitleDiv';

interface Props {
  dto?: ContentDto;
  isEdit?: boolean;
  onClick?: () => void;
  onCloseEvent?: () => void;
}

export const RowSectionDivItem: FunctionComponent<Props> = ({
  dto,
  isEdit,
  onClick,
  onCloseEvent,
}) => {
  const level = getKeyLevel(dto?.key);
  const { section } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const fontWeightNew = getFontWeight(level);
  const fontSizeNew = getFontSize(level);

  const handleEditClick = (key: string) => {
    navigate(`${EDIT_ROUTE}/${section}/${key}`);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    if (onCloseEvent !== undefined) {
      onCloseEvent();
    }
  };

  const handleCloseTwo = () => {
    if (onCloseEvent !== undefined) {
      onCloseEvent();
    }
  };

  const padding = getPadding(level);
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          bgcolor: '#FFFFFF',
          '&:hover': {
            bgcolor: '#eeeeee',
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          onClick={isEdit ? onClick : undefined}
          justifyContent="center"
        >
          <Stack direction="row" width="20px">
            {dto?.isFree ? undefined : (
              <AttachMoneyIcon sx={{ color: '#009688' }} />
            )}
            {dto?.isAuth ? <PersonIcon sx={{ color: '#ff9800' }} /> : undefined}
            {dto?.isTelegramPairing ? (
              <TelegramIcon sx={{ color: '#03a9f4' }} />
            ) : undefined}
          </Stack>
          <Stack paddingLeft={padding}>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: fontWeightNew,
                fontSize: fontSizeNew,
                color: '#000000',
              }}
              component="text"
            >
              {`${dto?.key} ${dto?.title}`}
            </Typography>
          </Stack>
        </Stack>
        {level === 1 && isEdit && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <IconButton
              title="Добавить новый раздел"
              size="large"
              sx={{ color: '#c62828' }}
              onClick={handleOpen}
            >
              <AddBoxIcon fontSize="large" />
            </IconButton>
          </Stack>
        )}
        {level === 1 && !isEdit && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <IconButton
              size="medium"
              title="Редактировать раздел"
              sx={{ color: '#f44336' }}
              onClick={() => handleEditClick(dto?.key ?? '')}
            >
              <EditIcon fontSize="medium" />
            </IconButton>
          </Stack>
        )}
        {level !== 1 && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <ContentItemDiv
              tooltip="2D конспект"
              imageContent={
                <AbstarctImage
                  fill={dto?.hasDocument ? ROW_SECTION_ICON_COLOR : '#e0e0e0'}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.hasDocument}
              dto={dto}
              type={ABSTRACT_TYPE}
            />
            <ContentItemDiv
              tooltip="Латинские карточки"
              imageContent={
                <PoolImage
                  fill={dto?.hasPool ? ROW_SECTION_ICON_COLOR : '#e0e0e0'}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.hasPool}
              dto={dto}
              type={POOL_TYPE}
            />
            <ContentItemDiv
              tooltip="2D тесты"
              imageContent={
                <TestImage
                  fill={dto?.hasTest ? ROW_SECTION_ICON_COLOR : '#e0e0e0'}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.hasTest}
              dto={dto}
              type={TEST_TYPE}
            />
            <ContentItemDiv
              tooltip="2D тесты с изображением"
              imageContent={
                <TestImageImage
                  fill={dto?.hasTestImg ? ROW_SECTION_ICON_COLOR : '#e0e0e0'}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.hasTestImg}
              dto={dto}
              type={TEST_IMAGE_TYPE}
            />
            <ContentItemDiv
              tooltip="Вопросы"
              imageContent={
                <CaseImage
                  fill={dto?.hasCase ? ROW_SECTION_ICON_COLOR : '#e0e0e0'}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.hasCase}
              dto={dto}
              type={CASE_TYPE}
            />
            <ContentItemDiv
              tooltip="Подсказки"
              imageContent={
                <CribImage
                  fill={dto?.hasCrib ? ROW_SECTION_ICON_COLOR : '#e0e0e0'}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.hasCrib}
              dto={dto}
              type={CRIB_TYPE}
            />
            <ContentItemDiv
              tooltip="Видео"
              imageContent={
                <VideoImage
                  fill={dto?.hasVideo ? ROW_SECTION_ICON_COLOR : '#e0e0e0'}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.hasVideo}
              dto={dto}
              type={VIDEO_TYPE}
            />
            <Divider orientation="vertical" />
            <ContentItemDiv
              tooltip="3D конспект"
              imageContent={
                <TextSnippetIcon
                  sx={{
                    color: dto?.has3dAbstract
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.has3dAbstract}
              dto={dto}
              type={TRID_ABSTRACT_TYPE}
            />
            <ContentItemDiv
              tooltip="3D конспект плюс"
              imageContent={
                <NoteAddIcon
                  sx={{
                    color: dto?.has3DAbstractExtended
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.has3DAbstractExtended}
              dto={dto}
              type={TRID_ABSTRACT_EXTENDED_TYPE}
            />
            <ContentItemDiv
              tooltip="3D атлас"
              imageContent={
                <ImageSearchIcon
                  sx={{
                    color: dto?.has3dAtlas
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.has3dAtlas}
              dto={dto}
              type={TRID_ATLAS_TYPE}
            />
            <ContentItemDiv
              tooltip="3D атлас плюс"
              imageContent={
                <AddPhotoAlternateIcon
                  sx={{
                    color: dto?.atlasPlusEnabled
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.atlasPlusEnabled}
              dto={dto}
              type={TRID_ATLAS_PLUS_TYPE}
            />
            <ContentItemDiv
              tooltip="3D тесты"
              imageContent={
                <QuizIcon
                  sx={{
                    color: dto?.has3dTest
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.has3dTest}
              dto={dto}
              type={TRID_TEST_TYPE}
            />
            <ContentItemDiv
              tooltip="3D репетитор"
              imageContent={
                <RecordVoiceOverIcon
                  sx={{
                    color: dto?.has3dVoice
                      ? ROW_SECTION_3D_ICON_COLOR
                      : '#e0e0e0',
                  }}
                  width="20"
                />
              }
              onCloseEvent={handleCloseTwo}
              isEdit={isEdit}
              isFind={dto?.has3dVoice}
              dto={dto}
              type={TRID_VOICE_TYPE}
            />
          </Stack>
        )}
      </Stack>
      <DialogAddTitleDiv open={openDialog} onClose={handleClose} />
    </div>
  );
};

RowSectionDivItem.defaultProps = {
  dto: undefined,
  isEdit: false,
  onClick: undefined,
  onCloseEvent: undefined,
};
