import React from 'react';
import { MainNavigationBar } from '../component/navigation-bar/MainNavigationBar';
import { GeneralPageLayout } from '../layout/GeneralPageLayout';
import { CorrectTaskDiv } from '../component/correct-task/CorrectTaskDiv';

export const CorrectTaskPage = () => (
  <GeneralPageLayout
    navigationBar={<MainNavigationBar />}
    elementCentr={<CorrectTaskDiv />}
  />
);
