import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { ContentDto } from '../../dto/ContentDto';
import { MyTextFiled } from '../UI/MyTextField';
import { EditTitleDto } from '../../dto/EditTitleDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiEditTitle } from '../../service/apiEditTitle';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

interface Props {
  open: boolean;
  onClose: () => void;
  dto?: ContentDto;
}

export const DialogEditDiv: FunctionComponent<Props> = ({
  open,
  dto,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { section } = useParams();
  const [editTitleDto, setEditTitleDto] = useState<EditTitleDto>({
    auth: dto?.isAuth ?? false,
    telegram: dto?.isTelegramPairing ?? false,
    key: dto?.key ?? '',
    paid: !dto?.isFree ?? true,
    purchaseId: dto?.purchaseId ?? '',
    newPurchaseId: dto?.newPurchaseId ?? '',
    title: dto?.title ?? '',
  });

  const handleClose = () => {
    onClose();
    setEditTitleDto({
      auth: false,
      telegram: false,
      key: '',
      paid: false,
      purchaseId: '',
      newPurchaseId: '',
      title: '',
    });
  };

  const handleChangeCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: number
  ) => {
    let check = false;
    if (event.target.checked) {
      check = true;
    }
    if (type === 0) {
      setEditTitleDto({ ...editTitleDto, auth: check });
    }
    if (type === 1) {
      setEditTitleDto({ ...editTitleDto, paid: check });
    }
    if (type === 2) {
      setEditTitleDto({ ...editTitleDto, telegram: check });
    }
  };

  const editTitle = async (route: string) => {
    if (editTitleDto.key === '' || editTitleDto.title === '') {
      return;
    }
    const apiResult: IApiResult = await apiEditTitle(route, editTitleDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      setEditTitleDto({
        auth: dto?.isAuth ?? false,
        telegram: dto?.isTelegramPairing ?? false,
        key: dto?.key ?? '',
        paid: !dto?.isFree ?? true,
        purchaseId: dto?.purchaseId ?? '',
        newPurchaseId: dto?.newPurchaseId ?? '',
        title: dto?.title ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%' },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '24px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`Раздел - ${dto?.key} ${dto?.title}`}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                <IconButton
                  title="Сохранить"
                  size="small"
                  onClick={() => editTitle(section ?? '')}
                >
                  <SaveIcon fontSize="large" sx={{ color: '#2196f3' }} />
                </IconButton>
              </Stack>
              <IconButton title="Закрыть" size="small" onClick={handleClose}>
                <CloseIcon fontSize="large" sx={{ color: '#000000' }} />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <MyTextFiled
              value={editTitleDto.title}
              onChange={(e) =>
                setEditTitleDto({
                  ...editTitleDto,
                  title: e.target.value,
                })
              }
              size="small"
              label="Наименование"
            />
            {editTitleDto?.purchaseId !== '' && (
              <MyTextFiled
                value={editTitleDto.purchaseId}
                size="small"
                onChange={(e) =>
                  setEditTitleDto({
                    ...editTitleDto,
                    purchaseId: e.target.value,
                  })
                }
                disabled
                label="Old Purchase id"
              />
            )}
            <MyTextFiled
              value={editTitleDto.newPurchaseId}
              size="small"
              onChange={(e) =>
                setEditTitleDto({
                  ...editTitleDto,
                  newPurchaseId: e.target.value,
                })
              }
              label="New Purchase id"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={editTitleDto.paid}
                  onChange={(e) => handleChangeCheck(e, 1)}
                />
              }
              label="Платный"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={editTitleDto.auth}
                  onChange={(e) => handleChangeCheck(e, 0)}
                />
              }
              label="Нужна авторизация"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={editTitleDto.telegram}
                  onChange={(e) => handleChangeCheck(e, 2)}
                />
              }
              label="Нужна привязка телеграм"
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogEditDiv.defaultProps = {
  dto: undefined,
};
