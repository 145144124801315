export const MAIN_COLOR_STRING = '#01c7d4';
export const MAIN_TITLE_FONTSIZE_STRING = '20px';
export const ACCESS_TOKEN_NAME = 'access';
export const LANG_NAME = 'lang';
export const REFRESH_TOKEN_NAME = 'refresh';
export const JSON_CONTENT_TYPE = 'application/json';
export const FORM_DATA_CONTENT_TYPE = 'form-data';
export const WARNING_DATA_CONTENT_TYPE = 'warning-data';
export const API_REQUEST_TIMEOUT_MILLISECONDS = 300_000;
export const API_REQUEST_TIMEOUT_MILLISECONDS_ERROR_STATUS = -10;
export const API_STATUS_PROBLEM =
  'Произошла ошибка, это все что мы знаем... Попробуйте повторить запрос позже';
export const SECRET_KEY_ADMIN = 'nurlat';
export const ABSTRACT_TYPE = 0;
export const POOL_TYPE = 2;
export const TEST_TYPE = 3;
export const TEST_IMAGE_TYPE = 4;
export const CASE_TYPE = 5;
export const CRIB_TYPE = 6;
export const VIDEO_TYPE = 7;
export const TRID_ABSTRACT_TYPE = 20;
export const TRID_ATLAS_TYPE = 21;
export const TRID_TEST_TYPE = 22;
export const TRID_VOICE_TYPE = 23;
export const DESIGN_FRONT_TYPE = 100;
export const TRID_ABSTRACT_EXTENDED_TYPE = 120;
export const TRID_ATLAS_PLUS_TYPE = 121;
export const SECTION_NAME = 'section';
export const TRID_NAME = '3d';
export const RUS_LANG = 'ru';
export const EN_LANG = 'en';
export const ROW_SECTION_ICON_COLOR = '#009688';
export const ROW_SECTION_3D_ICON_COLOR = '#ff9800';
export const DOWNLOAD_FILE_NUM = 1;
