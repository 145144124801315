import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Stack, Typography } from '@mui/material';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM, SECTION_NAME } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { ContentDto } from '../../dto/ContentDto';
import { apiGetContents } from '../../service/apiGetContents';
import { DesignRowSectionDivItem } from './DesignRowSectionDivItem';

export const DesignDiv = () => {
  const { section } = useParams();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [contentDtos, setContentDtos] = useState<ContentDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);
  const getContents = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetContents(route);
    if (apiResult.isSuccess) {
      setContentDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const handleCloseEvent = () => {
    setContentDtos([]);
    getContents(section ?? '');
  };

  useEffect(() => {
    getContents(section ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="90%" padding="10px" spacing={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 1000,
              fontSize: '30px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`${localStorage.getItem(SECTION_NAME)} - Дизайн`}
          </Typography>
        </Stack>
        <Divider />
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        {contentDtos.map((item) => (
          <div>
            <DesignRowSectionDivItem
              key={item.id}
              dto={item}
              onCloseEvent={handleCloseEvent}
            />
            <Divider />
          </div>
        ))}
      </Stack>
    </Stack>
  );
};
