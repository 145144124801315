import React, { FunctionComponent, useState } from 'react';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment, TextField } from '@mui/material';

interface Props {
  label: string;
  inputPassword?: any;
  width?: string;
  value?: any;
  error?: boolean;
  helperText?: string;
  size?: any;
  isVisible?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void;
}

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& .MuiFormLabel-root.Mui-disabled': {
    color: 'grey',
  },
});

export const MyPasswordTextField: FunctionComponent<Props> = ({
  label,
  inputPassword,
  width,
  value,
  error,
  helperText,
  size,
  isVisible,
  onChange,
}) => {
  const [textFieldType, setTextFieldType] = useState('password');
  const handleShowPassword = () => {
    if (textFieldType === 'password') {
      setTextFieldType('text');
    } else {
      setTextFieldType('password');
    }
  };

  return (
    <CssTextField
      label={label}
      value={value}
      variant="filled"
      size={size}
      type={textFieldType}
      onChange={onChange}
      error={error && value === ''}
      style={{
        fontFamily: 'sans-serif',
        width,
        visibility: isVisible ? 'visible' : 'collapse',
      }}
      helperText={error && value === '' ? 'Введите значение' : helperText}
      InputProps={{
        autoComplete: inputPassword,
        endAdornment: (
          <InputAdornment position="end">
            {textFieldType === 'password' && (
              <VisibilityIcon
                onClick={handleShowPassword}
                style={{ cursor: 'pointer' }}
              />
            )}
            {textFieldType !== 'password' && (
              <VisibilityOffIcon
                onClick={handleShowPassword}
                style={{ cursor: 'pointer' }}
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

MyPasswordTextField.defaultProps = {
  isVisible: true,
  inputPassword: 'password',
  width: '100%',
  value: '',
  size: 'large',
  error: false,
  helperText: '',
  onChange: undefined,
};
