import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { getDefaultAlertProps, getLang } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { SectionDto } from '../../dto/SectionDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetSections } from '../../service/apiGetSections';
import {
  API_STATUS_PROBLEM,
  EN_LANG,
  LANG_NAME,
  RUS_LANG,
} from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { SectionItemDiv } from './SectionItemDiv';

export const MainDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [lang, setLang] = useState(getLang());
  const [sectionDtos, setSectionDtos] = useState<SectionDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);

  const getSections = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetSections();
    if (apiResult.isSuccess) {
      setSectionDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const changeLang = (selectLang: string) => {
    setLang(selectLang);
    localStorage.setItem(LANG_NAME, selectLang);
  };

  useEffect(() => {
    getSections();
  }, [lang]);

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" height="100%" padding="10px" alignItems="center">
        <Stack width="40%" height="100%">
          <Grid container spacing={1}>
            {sectionDtos.map((item) => (
              <Grid item xs={6}>
                <SectionItemDiv key={item.route} dto={item} />
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '24px',
              color: lang === RUS_LANG ? '#FFFFFF' : '#e0e0e0',
              cursor: 'pointer',
            }}
            onClick={() => changeLang(RUS_LANG)}
            component="text"
          >
            Русский
          </Typography>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '24px',
              color: lang === EN_LANG ? '#FFFFFF' : '#e0e0e0',
              cursor: 'pointer',
            }}
            onClick={() => changeLang(EN_LANG)}
            component="text"
          >
            English
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
