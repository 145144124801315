import React, { FunctionComponent } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

interface Props {
  message: string;
  severity: AlertSeverityEnum;
  // eslint-disable-next-line react/require-default-props
  onClose?: () => void;
}

export const MyAlert: FunctionComponent<Props> = ({
  message,
  severity,
  onClose,
}) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={message !== ''}
    autoHideDuration={2000}
    onClose={onClose}
    style={{ width: '90%', marginTop: '80px' }}
  >
    <Alert variant="filled" severity={severity} onClose={onClose}>
      {message}
    </Alert>
  </Snackbar>
);
