import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../constant/constants';
import { NewsDto } from '../dto/NewsDto';
import { IApiParams } from '../interface/IApiParams';
import { getCookie, getLang, secureToken } from '../utils/utils';
import { getApi } from './getApi';

export const apiDeleteNews = async (dto: NewsDto) => {
  const params: IApiParams = {
    url: `/deleteNews.php?${secureToken()}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': getCookie(ACCESS_TOKEN_NAME),
      'Api-Lang': getLang(),
    },
  };
  return getApi(params, 'DELETE', dto);
};
