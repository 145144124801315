import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import GppGoodIcon from '@mui/icons-material/GppGood';
import LockIcon from '@mui/icons-material/Lock';
import { getDefaultAlertProps, getKeyLevel } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM, SECTION_NAME } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { ContentDto } from '../../dto/ContentDto';
import { apiGetContents } from '../../service/apiGetContents';
import { DialogCheck } from './DialogCheck';
import { ReleaseDivItem } from './ReleaseDivItem';
import { BaseDto } from '../../dto/BaseDto';
import { apiGetBlocBase } from '../../service/apiGetBlocBase';
import { apiBlocBase } from '../../service/apiBlocBase';

export const ReleaseDiv = () => {
  const { section } = useParams();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [contentDtos, setContentDtos] = useState<ContentDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);
  const [blocBaseDto, setBlocBaseDto] = useState<BaseDto>();
  const [openDialog, setOpenDialog] = useState(false);
  const getContents = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetContents(route);
    if (apiResult.isSuccess) {
      setContentDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getBlocBase = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetBlocBase(route);
    if (apiResult.isSuccess) {
      setBlocBaseDto(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const blocBase = async (route: string) => {
    const dto: BaseDto = {
      bloc: 0,
    };
    const apiResult: IApiResult = await apiBlocBase(route, dto);
    if (apiResult.isSuccess) {
      getBlocBase(route);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleBloc = () => {
    blocBase(section ?? '');
  };

  useEffect(() => {
    getContents(section ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!openDialog) {
      getBlocBase(section ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="90%" padding="10px" spacing={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 1000,
              fontSize: '30px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`${localStorage.getItem(SECTION_NAME)} - Структура`}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton size="large" onClick={handleOpen}>
              <GppGoodIcon fontSize="large" sx={{ color: '#4caf50' }} />
            </IconButton>
            {blocBaseDto?.bloc === 1 && (
              <IconButton size="large" onClick={handleBloc}>
                <LockIcon fontSize="large" sx={{ color: 'red' }} />
              </IconButton>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        {contentDtos.map((item) => {
          const level = getKeyLevel(item?.key);
          return (
            <div>
              {level === 1 && (
                <Stack>
                  <ReleaseDivItem
                    key={item.id}
                    dto={item}
                    isBloc={blocBaseDto?.bloc === 1}
                  />
                  <Divider />
                </Stack>
              )}
            </div>
          );
        })}
      </Stack>
      <DialogCheck open={openDialog} onClose={handleClose} />
    </Stack>
  );
};
