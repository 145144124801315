import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import KeyIcon from '@mui/icons-material/Key';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import SaveIcon from '@mui/icons-material/Save';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM, SECTION_NAME } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { SearchDto } from '../../dto/SearchDto';
import { apiGetSearch } from '../../service/apiGetSearch';
import { BASE_URL } from '../../setting/AppSettings';
import { apiUpdateSearch } from '../../service/apiUpdateSearch';
import { UpdateSearchDto } from '../../dto/UpdateSearchDto';
import { apiThumbToWebp } from '../../service/apiThumbToWebp';
import { apiJpgToWebp } from '../../service/apiJpgToWebp';
import { apiJpgToThumb } from '../../service/apiJpgToThumb';
import { apiAutoAddNewImage } from '../../service/apiAutoAddNewImage';
import { apiAutoAddKeyForNewImage } from '../../service/apiAutoAddKeyForNewImage';

export const SearchDiv = () => {
  const { section } = useParams();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [searchDtos, setSearchDtos] = useState<SearchDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);

  const getSearch = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetSearch(route);
    if (apiResult.isSuccess) {
      setSearchDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const updateSearch = async (route: string, row: SearchDto) => {
    setBackDrop(true);
    const dto: UpdateSearchDto = {
      fileName: row.latName,
      lat: row.realLatName,
      rus: row.rusName,
    };
    const apiResult: IApiResult = await apiUpdateSearch(route, dto);
    if (apiResult.isSuccess) {
      getSearch(route);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const addNewImage = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiAutoAddNewImage(route);
    if (!apiResult.isSuccess) {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const updateKeys = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiAutoAddKeyForNewImage(route);
    if (apiResult.isSuccess) {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const convertJpgWebp = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiJpgToWebp(route);
    if (apiResult.isSuccess) {
      const resultJson = apiResult.data.message;
      setAlertProps({
        message: resultJson,
        severity: AlertSeverityEnum.info,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const convertJpgToThumb = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiJpgToThumb(route);
    if (apiResult.isSuccess) {
      const resultJson = apiResult.data.message;
      setAlertProps({
        message: resultJson,
        severity: AlertSeverityEnum.info,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const convertThumbToWebp = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiThumbToWebp(route);
    if (apiResult.isSuccess) {
      const resultJson = apiResult.data.message;
      setAlertProps({
        message: resultJson,
        severity: AlertSeverityEnum.info,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    getSearch(section ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'latName',
      width: 250,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Изображения
        </Typography>
      ),
      editable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" alignItems="center" spacing={1}>
          <img
            alt={row.row.basisName}
            src={`${BASE_URL}/${section}/image/${row.row.basisName}.jpg`}
            width={100}
            style={{ cursor: 'pointer' }}
          />
          <img
            alt={row.row.latName}
            src={`${BASE_URL}/${section}/image/${row.row.latName}.jpg`}
            width={100}
            style={{ cursor: 'pointer' }}
          />
        </Stack>
      ),
    },
    {
      field: 'basisName',
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Информация
        </Typography>
      ),
      width: 350,
      editable: false,
      renderCell: (row) => (
        <Stack spacing={1}>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '12px',
              color: '#000000',
              letterSpacing: 1,
              whiteSpace: 'pre-line',
            }}
            component="text"
          >
            {`First: ${row.row.basisName}`}
          </Typography>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '12px',
              color: '#000000',
              letterSpacing: 1,
              whiteSpace: 'pre-line',
            }}
            component="text"
          >
            {`Second: ${row.row.latName}`}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'titleName',
      minWidth: 300,
      flex: 1,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          Раздел
        </Typography>
      ),
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '12px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.titleName.replaceAll('</br>', '\n')}
        </Typography>
      ),
    },
    {
      field: 'rusName',
      minWidth: 300,
      flex: 1,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          RUS
        </Typography>
      ),
      editable: true,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '12px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.rusName}
        </Typography>
      ),
    },
    {
      field: 'realLatName',
      minWidth: 300,
      flex: 1,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          LAT
        </Typography>
      ),
      editable: true,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '12px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.realLatName}
        </Typography>
      ),
    },
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Сохранить"
            size="small"
            onClick={() => updateSearch(section ?? '', row.row)}
          >
            <SaveIcon fontSize="small" sx={{ color: '#8e24aa' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="90%" padding="10px" spacing={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 1000,
              fontSize: '30px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`${localStorage.getItem(SECTION_NAME)} - Изображения`}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              size="large"
              title="Проверить новые изображения"
              onClick={() => addNewImage(section ?? '')}
            >
              <LibraryAddCheckIcon fontSize="large" sx={{ color: '#673ab7' }} />
            </IconButton>
            <IconButton
              size="large"
              title="Обновить ключи"
              onClick={() => updateKeys(section ?? '')}
            >
              <KeyIcon fontSize="large" sx={{ color: '#2196f3' }} />
            </IconButton>
            <IconButton
              size="large"
              title="Jpg - Webp"
              onClick={() => convertJpgWebp(section ?? '')}
            >
              <LooksOneIcon fontSize="large" sx={{ color: '#f44336' }} />
            </IconButton>
            <IconButton
              size="large"
              title="Jpg - Thumb"
              onClick={() => convertJpgToThumb(section ?? '')}
            >
              <LooksTwoIcon fontSize="large" sx={{ color: '#e91e63' }} />
            </IconButton>
            <IconButton
              size="large"
              title="Thumb - Webp"
              onClick={() => convertThumbToWebp(section ?? '')}
            >
              <Looks3Icon fontSize="large" sx={{ color: '#9c27b0' }} />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                  py: 1,
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: '5px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                  py: '10px',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                  {
                    outline: 'none !important',
                  },
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              rows={searchDtos}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...other}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </ThemeProvider>
        </Box>
      </Stack>
    </Stack>
  );
};
