import React, { useState } from 'react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { TelegramDiv } from './TelegramDiv';
import { NewsDiv } from './NewsDiv';
import { PushDiv } from './PushDiv';

export const MessagesDiv = () => {
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };
  return (
    <Stack width="100%" alignItems="center">
      <Stack width="90%" padding="10px" spacing={0}>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab label="Новости" />
            </Tabs>
          </Box>
        </Stack>
        <Stack>{valueTab === 0 && <NewsDiv />}</Stack>
        <Stack>{valueTab === 10000 && <TelegramDiv />}</Stack>
        <Stack>{valueTab === 20000 && <PushDiv />}</Stack>
      </Stack>
    </Stack>
  );
};
