import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { Card, Stack } from '@mui/material';
import { MyButton } from '../UI/MyButton';
import { MyPasswordTextField } from '../UI/MyPasswordTextField';
import { MyTextFiled } from '../UI/MyTextField';
import { IApiResult } from '../../interface/IApiResult';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyAlert } from '../UI/MyAlert';
import {
  ACCESS_TOKEN_NAME,
  API_STATUS_PROBLEM,
  REFRESH_TOKEN_NAME,
} from '../../constant/constants';
import { SignInDto } from '../../dto/SignInDto';
import { MAIN_ROUTE } from '../../constant/routes';
import { apiSignIn } from '../../service/apiSignIn';

export const SignInDiv = () => {
  localStorage.clear();
  const navigate = useNavigate();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [secureToken, setSecureToken] = useState('');
  const [signInDto, setSignInDto] = useState<SignInDto>({
    login: '',
    password: '',
  });
  const [, setCookie] = useCookies([ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME]);
  const [checkHelper, setCheckHelper] = useState(false);
  const signIn = async () => {
    if (secureToken !== 'ny2023') {
      return;
    }
    const apiResult: IApiResult = await apiSignIn(signInDto);
    if (apiResult.isSuccess) {
      const expiresAccess = new Date();
      expiresAccess.setTime(
        expiresAccess.getTime() + apiResult.data.access.valid * 1000
      );
      setCookie(ACCESS_TOKEN_NAME, apiResult.data.access.token, {
        path: '/',
        expires: expiresAccess,
      });
      const expiresRefresh = new Date();
      expiresRefresh.setTime(
        expiresRefresh.getTime() + apiResult.data.refresh.valid * 1000
      );
      setCookie(REFRESH_TOKEN_NAME, apiResult.data.refresh.token, {
        path: '/',
        expires: expiresRefresh,
      });
      navigate(MAIN_ROUTE);
    } else if (apiResult.status === 404) {
      setAlertProps({
        message: 'Неверный логин и/или пароль',
        severity: AlertSeverityEnum.error,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleSignIn = async () => {
    if (signInDto.login === '' || signInDto.password === '') {
      setCheckHelper(true);
      return;
    }
    await signIn();
  };

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '15%', minWidth: '250px' }}>
        <Stack width="100%" spacing={2}>
          <MyTextFiled
            value={signInDto.login}
            onChange={(e) =>
              setSignInDto({ ...signInDto, login: e.target.value })
            }
            size="small"
            error={checkHelper}
            label="Логин"
          />
          <MyPasswordTextField
            value={signInDto.password}
            onChange={(e) =>
              setSignInDto({ ...signInDto, password: e.target.value })
            }
            size="small"
            error={checkHelper}
            label="Пароль"
          />
          <MyTextFiled
            value={secureToken}
            onChange={(e) => setSecureToken(e.target.value)}
            size="small"
            error={checkHelper}
            label="PIN"
          />
          <MyButton text="Далее" background="#000000" onClick={handleSignIn} />
        </Stack>
      </Card>
    </Stack>
  );
};
