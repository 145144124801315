import React, { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonIcon from '@mui/icons-material/Person';
import { IconButton, Stack, Typography } from '@mui/material';
import { ContentDto } from '../../dto/ContentDto';
import {
  getDefaultAlertProps,
  getKeyLevel,
  getPadding,
} from '../../utils/utils';
import { DialogAddTitleDiv } from '../edit/DialogAddTitleDiv';
import { IApiResult } from '../../interface/IApiResult';
import { apiReleaseSection } from '../../service/apiReleaseSection';
import { IAlertProps } from '../../interface/IAlertProps';
import { MyAlert } from '../UI/MyAlert';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

interface Props {
  dto?: ContentDto;
  isBloc?: boolean;
  onClick?: () => void;
}

export const ReleaseDivItem: FunctionComponent<Props> = ({
  dto,
  isBloc,
  onClick,
}) => {
  const level = getKeyLevel(dto?.key);
  const { section } = useParams();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const releaseSection = async (route: string, mainNum: string) => {
    const apiResult: IApiResult = await apiReleaseSection(route, mainNum);
    if (apiResult.isSuccess) {
      setAlertProps({
        message: 'Раздел перенесен',
        severity: AlertSeverityEnum.info,
      });
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };
  const handleReleaseClick = (key: string) => {
    releaseSection(section ?? '', key);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const padding = getPadding(level);
  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      {level === 1 && (
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ background: isHover ? '#bdbdbd' : '#ffffff' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Stack
            direction="row"
            alignItems="center"
            onClick={isBloc ? onClick : undefined}
            justifyContent="center"
          >
            <Stack direction="row" width="20px">
              {dto?.isFree ? undefined : (
                <AttachMoneyIcon sx={{ color: 'green' }} />
              )}
              {dto?.isAuth ? (
                <PersonIcon sx={{ color: 'orange' }} />
              ) : undefined}
            </Stack>
            <Stack paddingLeft={padding}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '20px',
                  color: '#000000',
                }}
                component="text"
              >
                {`${dto?.key} ${dto?.title}`}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <IconButton
              size="large"
              sx={{ color: isBloc ? 'purple' : 'grey' }}
              onClick={() =>
                isBloc ? handleReleaseClick(dto?.key ?? '') : undefined
              }
            >
              <ArrowForwardIcon fontSize="large" />
            </IconButton>
          </Stack>
        </Stack>
      )}
      <DialogAddTitleDiv open={openDialog} onClose={handleClose} />
    </div>
  );
};

ReleaseDivItem.defaultProps = {
  dto: undefined,
  isBloc: false,
  onClick: undefined,
};
