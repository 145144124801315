import React, { FunctionComponent, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { ContentDto } from '../../dto/ContentDto';
import { DialogDesign } from '../contents/DialogDesign';

interface Props {
  imageContent: any;
  onCloseEvent: () => void;
  dto?: ContentDto;
}

export const DesignContentItemDiv: FunctionComponent<Props> = ({
  imageContent,
  dto,
  onCloseEvent,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    onCloseEvent();
  };

  return (
    <div>
      <Stack alignItems="center" justifyContent="center">
        <IconButton size="medium" onClick={handleOpen}>
          {imageContent}
        </IconButton>
      </Stack>
      <DialogDesign open={openDialog} dto={dto} onClose={handleClose} />
    </div>
  );
};

DesignContentItemDiv.defaultProps = {
  dto: undefined,
};
