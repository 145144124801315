import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../constant/constants';
import { IApiParams } from '../interface/IApiParams';
import { getCookie, getLang, secureToken } from '../utils/utils';
import { getApi } from './getApi';

export const apiDeleteContent = async (
  route: string,
  type: number,
  key: string
) => {
  const params: IApiParams = {
    url: `/deleteContent.php?baseName=${route}&${secureToken()}&type=${type}&key=${key}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': getCookie(ACCESS_TOKEN_NAME),
      'Api-Lang': getLang(),
    },
  };
  return getApi(params, 'GET');
};
