import { IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { FunctionComponent, useState } from 'react';
import classes from './AddFile.module.css';
import { IFileUpload } from '../../interface/IFileUpload';
import { apiUploadFile } from '../../service/apiUloadFile';
import { ContentDto } from '../../dto/ContentDto';
import { IApiResult } from '../../interface/IApiResult';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { API_STATUS_PROBLEM } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

interface Props {
  name: string;
  icon: any;
  typeContent: number;
  dto?: ContentDto;
}

export const AddFile: FunctionComponent<Props> = ({
  name,
  icon,
  typeContent,
  dto,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const { section } = useParams();
  const mainTitleNum = dto?.key.split('.') ?? 'dsggggfdg';
  const uploadFile = async (upload: IFileUpload[]) => {
    if (upload.length !== 0) {
      const apiFetches: any[] = [];
      upload.forEach((item) => {
        apiFetches.push(
          apiUploadFile(
            mainTitleNum[0],
            typeContent,
            dto?.key ?? '12121212',
            dto?.title ?? '12121212',
            section ?? '',
            item
          )
        );
      });
      const apiResults: IApiResult[] = await Promise.all(apiFetches);
      for (let i = 0; i < apiResults.length; i += 1) {
        if (apiResults[i].isSuccess) {
          setAlertProps({
            message: apiResults[i].data.message,
            severity: AlertSeverityEnum.info,
          });
        } else {
          setAlertProps({
            message: API_STATUS_PROBLEM,
            severity: AlertSeverityEnum.error,
          });
        }
      }
    }
  };

  const onInputFileChange = async (event: any) => {
    let sizeUploadFiles = 0;
    const dataArray = [];
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i += 1) {
        if (event.target.files[i].size > sizeUploadFiles) {
          sizeUploadFiles = event.target.files[i].size;
        }
        const formData = new FormData();
        formData.append('fileToUpload', event.target.files[i]);
        const dataArrayItem: IFileUpload = {
          data: formData,
          name: event.target.files[i].name,
        };
        dataArray.push(dataArrayItem);
      }
    }
    await uploadFile(dataArray);
  };

  return (
    <Stack>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <IconButton
        title="Загрузить"
        size="small"
        sx={{ color: '#128DFF' }}
        className={classes.personalImage}
      >
        <label htmlFor="inputFile">
          <Stack direction="row">
            <input
              id="inputFile"
              name={name}
              type="file"
              onChange={onInputFileChange}
              multiple={false}
            />
            {icon}
          </Stack>
        </label>
      </IconButton>
    </Stack>
  );
};

AddFile.defaultProps = {
  dto: undefined,
};
