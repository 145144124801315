import React, { FunctionComponent, useEffect, useState } from 'react';
import FileDownload from 'js-file-download';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { IApiResult } from '../../interface/IApiResult';
import {
  API_STATUS_PROBLEM,
  DOWNLOAD_FILE_NUM,
} from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyAlert } from '../UI/MyAlert';
import { ContentDto } from '../../dto/ContentDto';
import { apiGetAtlas3DPlus } from '../../service/apiGetAtlas3DPlus';
import { TextDto } from '../../dto/TextDto';
import { apiSaveAtlas3DPlus } from '../../service/apiSaveAtlas3DPlus';
import { MyTextFiled } from '../UI/MyTextField';
import { BackDropDiv } from '../backdrop/BackDropDiv';

interface Props {
  open: boolean;
  isEdit?: boolean;
  onClose: () => void;
  dto?: ContentDto;
}

export const DialogAtlas3DPlus: FunctionComponent<Props> = ({
  open,
  dto,
  isEdit,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [atlas, setAtlas] = useState('');
  const [backDrop, setBackDrop] = useState(false);
  const handleClose = () => {
    setAtlas('');
    onClose();
  };
  const { section } = useParams();

  const getAtlas3DPlus = async (
    route: string,
    key: string,
    download?: number
  ) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetAtlas3DPlus(route, key, download);
    if (apiResult.isSuccess) {
      if (download === undefined) {
        setAtlas(apiResult.data);
      } else {
        FileDownload(
          apiResult.data,
          `${route}_3D_атлас_плюс_${dto?.key}_${dto?.title}_##_atlasPlusList.txt` ??
            ''
        );
      }
    } else if (apiResult.status !== 404) {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const saveText = async (route: string, key: string, title: string) => {
    const textDto: TextDto = {
      text: atlas,
    };
    const apiResult: IApiResult = await apiSaveAtlas3DPlus(
      route,
      key,
      title,
      textDto
    );
    if (apiResult.isSuccess) {
      setAlertProps({
        message: 'Данные записаны',
        severity: AlertSeverityEnum.info,
      });
      getAtlas3DPlus(section ?? '', dto?.key ?? '');
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
  };

  useEffect(() => {
    if (open) {
      getAtlas3DPlus(section ?? '', dto?.key ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', width: '100%', height: '100%' },
        }}
      >
        <BackDropDiv open={backDrop} />
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '24px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {`3D атлас расширенный - ${dto?.key} ${dto?.title}`}
            </Typography>
            <Stack direction="row" spacing={1}>
              {!isEdit && (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    title="Скачать"
                    size="small"
                    onClick={() =>
                      getAtlas3DPlus(
                        section ?? '',
                        dto?.key ?? '',
                        DOWNLOAD_FILE_NUM
                      )
                    }
                  >
                    <DownloadIcon fontSize="large" sx={{ color: '#9c27b0' }} />
                  </IconButton>
                </Stack>
              )}
              {isEdit && (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    title="Сохранить"
                    size="small"
                    onClick={() =>
                      saveText(section ?? '', dto?.key ?? '', dto?.title ?? '')
                    }
                  >
                    <SaveIcon fontSize="large" sx={{ color: '#2196f3' }} />
                  </IconButton>
                </Stack>
              )}
              <IconButton title="Закрыть" size="small" onClick={handleClose}>
                <CloseIcon fontSize="large" sx={{ color: '#000000' }} />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack>
            {!isEdit && (
              <Typography
                sx={{ wordBreak: 'break-word' }}
                style={{
                  fontFamily: 'sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#000000',
                  letterSpacing: 1,
                  display: 'inline-block',
                  whiteSpace: 'pre-line',
                }}
                component="text"
              >
                {`${atlas}`}
              </Typography>
            )}
            {isEdit && (
              <MyTextFiled
                value={atlas}
                multiLine
                onChange={(e) => setAtlas(e.target.value)}
                size="small"
                label=""
              />
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogAtlas3DPlus.defaultProps = {
  isEdit: false,
  dto: undefined,
};
