import { ACCESS_TOKEN_NAME, JSON_CONTENT_TYPE } from '../constant/constants';
import { Update3dDto } from '../dto/Update3dDto';
import { IApiParams } from '../interface/IApiParams';
import { getCookie, getLang, secureToken } from '../utils/utils';
import { getApi } from './getApi';

export const apiUpdateTrid = async (route: string, dto: Update3dDto) => {
  const params: IApiParams = {
    url: `/update3dContent.php?baseName=${route}&${secureToken()}`,
    headers: {
      'Content-Type': JSON_CONTENT_TYPE,
      'Access-Token': getCookie(ACCESS_TOKEN_NAME),
      'Api-Lang': getLang(),
    },
  };
  return getApi(params, 'POST', dto);
};
