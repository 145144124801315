import {
  ACCESS_TOKEN_NAME,
  FORM_DATA_CONTENT_TYPE,
} from '../constant/constants';
import { IApiParams } from '../interface/IApiParams';
import { IFileUpload } from '../interface/IFileUpload';
import { getCookie, getLang, secureToken } from '../utils/utils';
import { getApi } from './getApi';

export const apiUploadFile = async (
  mainNum: string,
  type: number,
  key: string,
  titleName: string,
  route: string,
  fileUpload: IFileUpload
) => {
  const params: IApiParams = {
    url: `/addFile.php?baseName=${route}&mainNum=${mainNum}&type=${type}&key=${key}&titleName=${titleName}&${secureToken()}`,
    headers: {
      'Content-Type': FORM_DATA_CONTENT_TYPE,
      'Access-Token': getCookie(ACCESS_TOKEN_NAME),
      'Api-Lang': getLang(),
    },
  };
  return getApi(params, 'POST', fileUpload.data);
};
