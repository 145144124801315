import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { API_STATUS_PROBLEM, SECTION_NAME } from '../../constant/constants';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { SearchDto } from '../../dto/SearchDto';
import { apiGetShop } from '../../service/apiGetShop';
import { PurchaseDto } from '../../dto/PurchaseDto';
import { UpdatePurchaseDto } from '../../dto/UpdatePurchaseDto';
import { apiUpdateShop } from '../../service/apiUpdateShop';

export const PurchaseDiv = () => {
  const { section } = useParams();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [searchDtos, setSearchDtos] = useState<SearchDto[]>([]);
  const [backDrop, setBackDrop] = useState(false);

  const getShop = async (route: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetShop(route);
    if (apiResult.isSuccess) {
      setSearchDtos(apiResult.data);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const updateShop = async (route: string, row: PurchaseDto) => {
    setBackDrop(true);
    const dto: UpdatePurchaseDto = {
      purchaseId: row.id,
      purchaseDescription: row.purchaseDescription,
      bullets: row.bullets,
      price: row.price,
      title: row.title,
    };
    const apiResult: IApiResult = await apiUpdateShop(route, dto);
    if (apiResult.isSuccess) {
      getShop(route);
    } else {
      setAlertProps({
        message: API_STATUS_PROBLEM,
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    getShop(section ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    ruRU
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          PURCHASE ID
        </Typography>
      ),
      editable: false,
      renderCell: (row) => (
        <Stack spacing={2}>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              color: '#000000',
              letterSpacing: 1,
              whiteSpace: 'pre-line',
            }}
            component="text"
          >
            {`${row.row.key} ${row.row.title}`}
          </Typography>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              color: '#000000',
              letterSpacing: 1,
              whiteSpace: 'pre-line',
            }}
            component="text"
          >
            {row.row.id}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'purchaseDescription',
      width: 350,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          DESCRIPTION
        </Typography>
      ),
      editable: true,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.purchaseDescription}
        </Typography>
      ),
    },
    {
      field: 'bullets',
      width: 350,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          BULLETS
        </Typography>
      ),
      editable: true,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.bullets}
        </Typography>
      ),
    },
    {
      field: 'type',
      width: 150,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          TYPE
        </Typography>
      ),
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.type}
        </Typography>
      ),
    },
    {
      field: 'quantity',
      width: 100,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          QUANTITY
        </Typography>
      ),
      editable: false,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.quantity}
        </Typography>
      ),
    },
    {
      field: 'price',
      width: 100,
      renderHeader: () => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          PRICE
        </Typography>
      ),
      editable: true,
      renderCell: (row) => (
        <Typography
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            color: '#000000',
            letterSpacing: 1,
            whiteSpace: 'pre-line',
          }}
          component="text"
        >
          {row.row.price}
        </Typography>
      ),
    },
    {
      field: 'id1',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title="Сохранить"
            size="small"
            onClick={() => updateShop(section ?? '', row.row)}
          >
            <SaveIcon fontSize="small" sx={{ color: '#8e24aa' }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <Stack width="100%" alignItems="center">
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="80%" padding="40px" spacing={0}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 1000,
              fontSize: '30px',
              color: '#000000',
              letterSpacing: 1,
            }}
            component="text"
          >
            {`${localStorage.getItem(SECTION_NAME)} - Магазин`}
          </Typography>
        </Stack>
        <Divider />
        <Stack marginBottom="40px">
          <div> </div>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                  py: 1,
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                  py: '5px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                  py: '10px',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                  {
                    outline: 'none !important',
                  },
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              rows={searchDtos}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...other}
              pageSize={20}
              rowsPerPageOptions={[20]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </ThemeProvider>
        </Box>
      </Stack>
    </Stack>
  );
};
